<h2 mat-dialog-title>Action Condition</h2>

<mat-dialog-content class="mat-typography">
  <div fxLayout="row">
    <div fxLayout="column"></div>
    <div fxLayout="column">
      <div fxLayout="row"> Perform the Action if
        <mat-form-field class="no-outline" style="width: 75px;margin-top: -16px;">
          <mat-select [(ngModel)] = "dataInput.action.condition.conditionOperator" [compareWith]="compareOptions">
            <mat-option [value]="option" *ngFor="let option of conditionOperators">
              {{ option.name }}</mat-option>
          </mat-select>
        </mat-form-field>
          of the following conditions match</div>

        <div fxLayout="row">
          <div>
            <app-filter-config
              [filter]="{filterEnabled: true, filterItems: dataInput.action.condition.conditions }"
              [fieldListingMap]="{sourceFields: sourceFields, systemFields: systemFields}"
              [boxObjectAttributes]="sourceFields"
              [hideToggle]="true"
              [showAttributePicker]="true"
              [lockFilterType]="true"
              (selectedFilter)="filterChanged($event,  dataInput.action.condition)"
              (filtersValid)="filtersValid($event)"
            >
            </app-filter-config>
          </div>
        </div>
    </div>
    <div fxLayout="column"></div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-raised-button color="primary" [disabled]="isActionDisable" (click)="onSave()" cdkFocusInitial>Save</button>
</mat-dialog-actions>




