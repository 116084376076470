import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { AdminService } from 'src/app/modules/admin/admin.service';

interface TemplateData {
  bannerImage?: string,  // text (url)
  screenshots?: string[],// array of text (urls)
  tagline?: string,      // text
  description?: string,  // textarea
  keyFeatures?: string,  // richtext
  categories?: string  // chip input,
  consentType?: string // radio
}

@Component({
  selector: 'app-collect-template-data',
  templateUrl: './collect-template-data.component.html',
  styleUrls: ['./collect-template-data.component.scss']
})
export class CollectTemplateDataComponent implements OnInit {

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @Output() emitTemplateData = new EventEmitter<any>();
  @Input() bloomMeta: any
  @Input() flow: any
  @Input() form: any
  @Input() screenshots: any
  @Input() banner: any


  entityType: string; // bloom | flow

  templateData: TemplateData = {
    bannerImage: '',
    screenshots: [],
    tagline: '',
    description: '',
    keyFeatures: '',
    categories: '',
    consentType: ''
  }

  dataCloneConsentType: string = ''
  availableConsentTypes: any[] = [
    {
      name: "Allow cloning structure only",
      value: "STRUCTURE"
    },
    {
      name: "Allow cloning structure and data",
      value: "STRUCTURE_WITH_DATA"
    }
  ]
  isConsentRequired: boolean = false
  // bloomConnections: any[] = []

  keyFeaturesWidgetMeta: any
  categories: string[] = []

  newFeature: string = ''
  newFeatureActive: boolean = false
  storePath: string
  blur: boolean = false

  constructor(
    private adminService: AdminService,
    private metaService: MetaService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

    if (this.bloomMeta) {
      this.entityType = 'bloom'
      // this.checkConnections()
      this.storePath = "bloom/template_data/" + this.bloomMeta.code;

    } else if(this.flow) {
      this.entityType = 'flow'
      this.storePath = "flow/template_data/" + this.flow._id;
    } else if(this.form) {
      this.entityType = 'form'
      this.storePath = "form/template_data/" + this.form.code;
    }
    this.initializeTemplate()

    // this.initializeTemplate()
  }

  ngOnChanges(changes){

    if(changes?.screenshots?.currentValue) {
      this.screenshots = changes.screenshots.currentValue;
      this.cdr?.detectChanges();
    }

    if(changes?.banner?.currentValue) {
      this.banner = changes?.banner?.currentValue;
      this.cdr?.detectChanges();
    }
  }

  // isValidTemplateData(){
  //   if(this.bloomMeta.template){
  //     if(!this.templateData.bannerImage ||
  //       !this.templateData.categories.length ||
  //       !this.templateData.description ||
  //       !this.templateData.keyFeatures.length ||
  //       !this.templateData.screenshots.length ||
  //       !this.templateData.tagline
  //     ){
  //       return false
  //     }
  //   }
  //   return true
  // }

  // async checkConnections(){
  //   this.bloomConnections = await this.metaService.getBloomConnections(this.bloomMeta.page_structure_id)
  //   console.log("bloom connections fetched", this.bloomConnections)
  //   this.bloomConnections.forEach(conn => {
  //     // fetch all used connections

  //     // check if supports clone

  //     // if yes, ask for consent
  //   })
  // }

  async initializeTemplate(){
    if(this.bloomMeta?.templateData){
      this.templateData = this.bloomMeta.templateData
    }else if(this.flow?.templateData){
      this.templateData = this.flow.templateData
    } else if(this.form?.templateData){
      this.templateData = this.form.templateData
    }

    // if(!this.templateData.bannerImage && this.banner) this.templateData.bannerImage = this.banner;
    // if((!this.templateData.screenshots || this.templateData.screenshots.length == 0) && Array.isArray(this.screenshots)) this.templateData.screenshots = this.screenshots;
    // if(this.templateData.consentType){
    //   this.dataCloneConsentType = this.templateData.consentType
    // }

    console.log("this.templateData", this.templateData)
    if(Array.isArray(this.templateData.categories)){
      this.categories = this.templateData.categories
    }else{
      this.templateData.categories.split(',').forEach(cat => this.categories.push(cat))
      if(this.categories[0] == ""){
        this.categories.splice(0, 1)
      }
    }

    // handle keyFeaturesWidgetMeta
    this.keyFeaturesWidgetMeta = WidgetManager.getWidget('richtext')

    let keyFeaturesString = ''
    if(!Array.isArray(this.templateData.keyFeatures)) keyFeaturesString = this.templateData.keyFeatures
    this.keyFeaturesWidgetMeta.setValue(keyFeaturesString)

    this.templateDataCollected()
  }

  // saveNewFeature(){
  //   if(this.newFeature.trim().length){
  //     this.templateData.keyFeatures.push(this.newFeature)
  //     this.newFeature = ''
  //     this.newFeatureActive = false
  //     this.templateDataCollected()
  //   }
  // }

  // removeFeature(i){
  //   this.templateData.keyFeatures.splice(i, 1)
  // }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.categories.push(value);
    }
    // Clear the input value
    event.chipInput!.clear();
    this.templateDataCollected()
  }

  remove(chip: any, index): void {
    if (index >= 0) {
      this.categories.splice(index, 1);
    }
    this.templateDataCollected()
  }

  async bannerImageInput(event){
    console.log("banner image input detected, will upload now", event)
    this.blur = true
    let res
    try{
      let email = this.bloomMeta?.publisher_email || this.flow?.created_by || this.form?.created_by;
      res = await this.adminService.fileUpload(event, email)
    }catch(e){
      console.log("failed to upload banner image", e)
    }
    this.blur = false
    console.log("banner image uploaded", res)
    this.templateData.bannerImage = res
    this.templateDataCollected()
  }

  /**
   * @Input data: [] // array of { image: any, index: number }
   */
  async screenshotsImageInput(data){
    console.log("screenshot image(s) input detected, will upload now", data)
    this.blur = true
    let imageUrls = []
    let res
    for(let i = 0; i < data.length; i ++){
      res = undefined
      try{
        let email = this.bloomMeta?.publisher_email || this.flow?.created_by || this.form?.created_by
        res = await this.adminService.fileUpload(data[i].image, email)
        if(typeof data[i].index == 'number' && data[i].index > -1) this.templateData.screenshots[data[i].index] = res
        else imageUrls.push(res)
      }catch(e){
        console.log("failed to upload screenshot image", e)
      }
    }
    this.blur = false
    console.log("screenshot image uploaded", imageUrls)
    this.templateData.screenshots = this.templateData.screenshots.concat(imageUrls)
    console.log("screenshots final", this.templateData.screenshots)
    this.templateDataCollected()
  }

  keyFeaturesReceived(data){
    console.log("key features received", data)
    this.templateData.keyFeatures = data.value
    this.templateDataCollected()
  }

  templateDataCollected(status?: string){
    let categoriesString = ''
    this.categories.forEach((category, i) => {
      categoriesString += category
      if(i < this.categories.length - 1) categoriesString += ','
      this.templateData.categories = categoriesString
    })

    if(status == 'cancel'){
      this.emitTemplateData.emit(null)
    }else{
      // if(this.isValidTemplateData()){
      //   this.emitTemplateData.emit(this.templateData)
      // }
      this.emitTemplateData.emit(this.templateData)
    }
  }

  consentTypeSelected(event){
    console.log("consent type selected", event)
    this.dataCloneConsentType = event.value
    this.templateData["consentType"] = this.dataCloneConsentType
    this.templateDataCollected()
  }

}
