export const CommonConfig: any = {
  props: ['backgroundColor'],
  backgroundColor: {
    displayName: 'Background Color',
    type: 'color-picker',
    value: 'transparent',
  }
}

export const AvailableOptions: any = {
  appearance: {
    widgetsSupported: ["input", "select", 'autocomplete', "textarea", "connection", "connection-list"],
    availableOptions: [
      "standard",
      "outline"
    ]
  },
  buttonType: {
    widgetsSupported: ["button"],
    availableOptions: [
      "Basic",
      "Raised",
      "Stroked",
      //"Flat",
      "Icon",
      "Fab",
      "Mini-fab"
    ]
  },
  buttonColorType: {
    widgetsSupported: ["button"],
    availableOptions: [
      "primary",
      "accent",
      "warn"
    ]
  },
  tagType: {
    widgetsSupported: ["tags"],
    availableOptions: [
      "Basic",
      "Raised",
      "Stroked",
      "Flat"
    ]
  },
  tagColorType: {
    widgetsSupported: ["tags"],
    availableOptions: [
      "primary",
      "accent",
      "warn"
    ]
  },
  width: {
    widgetsSupported: ["image"],
    availableOptions: [
      "50%",
      "60%",
      "70%",
      "80%",
      "90%",
      "100%",
    ]
  },
}
