
<div fxLayout="column" fxFlex="1"></div>
    <div fxLayout="column">
      <div style="padding-bottom: 15px;" fxLayout>
        <button matTooltip="Current workspace" class="workspace-menu-button" mat-button [matMenuTriggerFor]="menu">
          <mat-icon class="workspace-logo">workspace_premium</mat-icon>
           {{connectionService.selectedWorkSpaceMap.name}}
           <mat-icon>expand_more</mat-icon>
        </button>
        <mat-spinner class="centerOfAll" color="primary" strokeWidth="2" [diameter]="20" *ngIf="spinner"></mat-spinner>
        <button matTooltip="Current workspace" class="workspace-menu-button" mat-button style="left: 1rem;" *ngIf="!isWorkspaceLoaded" (click)="customInit()">
          <mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center" style="margin-right: .3em;">refresh</mat-icon>
          <span>Refresh</span>
        </button>
      </div>

      <mat-menu class="workspace-menu" #menu="matMenu" >

        <div class="workspace-head" fxLayout="row" fxLayoutAlign="center center">
          <b>Current Workspace</b>
        </div>
        <div mat-menu-item style="height: auto;">
          <div fxLayout="row" >
            <div fxLayoutAlign="center start" fxLayout="column">
              <mat-icon  class="workspace-logo">workspace_premium</mat-icon>
            </div>
            <div fxLayoutAlign="center start"  fxLayout="column">
              {{connectionService.selectedWorkSpaceMap.name}} &nbsp;&nbsp;
            </div>
            <div fxLayoutAlign="center end" fxLayout="column">
              <mat-icon *ngIf="permission.hasAccess(['workspace.edit'])" style="margin: 0px;"  matTooltip="Edit workspace"
              (click)="openWorkspace('edit', connectionService.selectedWorkSpaceMap)">
              edit</mat-icon>
            </div>
          </div>
        </div>

        <div mat-menu-item style="height: auto;" *ngIf="permission.hasAccess(['workspace.management'])">
          <div fxLayout="row" (click)="sendWorkspaceInvitation(connectionService.selectedWorkSpaceMap);" >
            <div fxLayoutAlign="center start" fxLayout="column">
              <mat-icon  class="workspace-logo">share</mat-icon>
            </div>
            <div fxLayoutAlign="center start"  matTooltip="Invite People to current workspace" fxLayout="column">
              Invite People
            </div>
          </div>
        </div>

        <div mat-menu-item style="height: auto;">
          <div fxLayout="row" (click)="viewMembers(connectionService.selectedWorkSpaceMap._id);" >
            <div fxLayoutAlign="center start" fxLayout="column">
              <mat-icon  class="workspace-logo">groups</mat-icon>
            </div>
            <div fxLayoutAlign="center start"  matTooltip="Current workspace members" fxLayout="column">
              Members
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div *ngIf="connectionService.workSpaces?.length > 1" class="workspace-head" fxLayout="row" fxLayoutAlign="center center">
          <b>Other Workspaces</b>
        </div>

        <div fxLayout="column" class="workspaces" *ngFor="let workSpace of connectionService.workSpaces?.slice(0,5); let i = index" fxLayoutAlign="center start">
          <div *ngIf="connectionService.selectedWorkSpaceMap._id != workSpace._id" fxLayout="row" style="height: 2.5rem; cursor: pointer; padding-left: 1.5rem">
            <div matTooltip="Change to '{{workSpace.name}}' workspace" (click)="workspaceSelected(workSpace)" fxLayout="column" fxLayoutAlign="center start">
              <mat-icon class="workspace-logo">workspace_premium</mat-icon>
            </div>
            <div matTooltip="Change to '{{workSpace.name}}' workspace" (click)="workspaceSelected(workSpace)" fxLayout="column" fxLayoutAlign="center start">
              {{workSpace.name}} &nbsp;&nbsp;
            </div>
          </div>
        </div>

        <div *ngIf="sharedWorkSpaces?.length>0" class="workspace-head" fxLayout="row" fxLayoutAlign="center center">
          <b>Shared Workspaces</b>
        </div>

        <div fxLayout="column" class="workspaces" *ngFor="let workSpace of sharedWorkSpaces?.slice(0,5); let i = index" fxLayoutAlign="center start">
          <div *ngIf="connectionService.selectedWorkSpaceMap._id != workSpace._id" fxLayout="row" style="height: 2.5rem; cursor: pointer; padding-left: 1.5rem">
            <div matTooltip="Change to '{{workSpace.name}}' workspace" (click)="workspaceSelected(workSpace)" fxLayoutAlign="center start" fxLayout="column">
              <mat-icon class="workspace-logo">workspace_premium</mat-icon>
            </div>
            <div matTooltip="Change to '{{workSpace.name}}' workspace" (click)="workspaceSelected(workSpace)" fxLayoutAlign="center start" fxLayout="column">
              {{workSpace.name}} &nbsp;&nbsp;
            </div>
          </div>
        </div>

        <div *ngIf="connectionService.workSpaces?.length > 5">
          <button mat-button style="margin-top: 1rem;" (click)="listAllWorkspaces()">
            <mat-icon>format_list_numbered</mat-icon>
            <span style="margin-left: 0.5rem;">See all workspaces</span>
          </button>
        </div>

        <div mat-menu-item *ngIf="permission.hasAccess(['workspace.edit'])">
          <div fxLayout="row" (click)="openWorkspace('create');" >
            <div fxLayoutAlign="center start" fxLayout="column">
              <mat-icon  class="workspace-logo">add</mat-icon>
            </div>
            <div fxLayoutAlign="center start"  matTooltip="Create new workspace" fxLayout="column">
              Create new
            </div>
          </div>

        </div>

      </mat-menu>
    </div>
<div fxLayout="column" fxFlex="2"></div>
