import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaService } from '../../services/meta-service';
import { PageService } from '../../services/page-service.service';
import { BaseAction } from './BaseAction';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
// import { BoxService } from 'src/app/bloom/services/box-service.service';
import { WidgetManager } from '../WidgetManager';
import { BoxService } from 'src/app/shared/services/box.service';
import { ActionServiceUtility } from './ActionServiceUtility';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ContextDataService } from 'src/app/shared/services/context-data.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationAction extends BaseAction {
  constructor(
    public router: Router,
    public metaService: MetaService,
    public connectionService: ConnectionService,
    public tokenUtil: TokenUtil,
    public boxService: BoxService,
    public pageService: PageService,
    private snackBar: MatSnackBar,
    public actionServiceUtility: ActionServiceUtility,
    public expressionUtility: ExpressionUtility,
    public spinnerService: SpinnerService,
    public contextDataService: ContextDataService
  ) {
    super();
  }

  widgetManager: any = WidgetManager;

  getActionDetail() {
    return {
      name: 'Application',
      id: 'application',
    };
  }

  getActionConfiguration() {
    let config = {};
    return config;
  }

  public async doAction(options?: any, event?: any) {
    let actionResult: any
    try{
      let actionMap = options.actionMap;
      let onMouseEvent = options.event;
      this.spinnerService.show();

      if(!this.actionServiceUtility.isEventMatch(onMouseEvent, event)) return;
      console.log('inside do action', options);
      let mapping = actionMap.mapping;
      let box = actionMap.boxId;
      let valueMap = actionMap?.valueMap || null;

      let boxToken: null;

      let preauthenticatedToken =this.connectionService.getPreAuthenticatedTokenForEmail(this.metaService.publisher_email);
      preauthenticatedToken = this.metaService.publisher_email ? preauthenticatedToken : this.connectionService.preAuthenticatedToken;
      console.log("[PRE AUTHENTICATED TOKEN]",preauthenticatedToken);

      if(actionMap.boxId == "starch"){
        box = actionMap.baseMap?.box_id;
        boxToken = actionMap?.boxConfigToken;
      } else {

        let connectionId = actionMap.connection;

        if(actionMap.whiteLabelConnection && actionMap?.whiteLabelMap?.widget){
          connectionId = await this.getWhiteLabeledConnection(actionMap?.whiteLabelMap?.widget)
        }

        console.log("connectionId", connectionId)
        console.log("actionMap", actionMap)
        let connection: any
        try{
          connection = await this.connectionService.getConnection(connectionId, preauthenticatedToken);
        }catch(e){
          console.error("could not get connection", e)
          this.openSnackBar({iconname: "error"}, e)
        }
        boxToken = connection.box_token;
        console.log("connection fetched", connection)
      }

      var statelessToken = await this.tokenUtil.getStatelessToken();

      let actionId = actionMap.action;
      let actionFunType = 'box';
      console.log("[ACTION ID]", actionId);
      if (actionId.includes('/')) actionFunType = 'boxObject';
      let actionMode = actionMap?.actionMode || null;
      if(actionId.includes('/save')){ //for save
        let boxObjectId = actionId.split("/")[0];
        console.log("CURRENT ACTION MODE SET TO : ", actionMode);
        if(!actionMode) actionMode = "create";
        if(actionMode == "create") mapping = await this.checkAndGetBoxAction(box, actionId, boxToken, mapping, options.actionMap.inputParams);
        console.log("mapping", mapping)
        actionId = boxObjectId + "/" + actionMode;
        console.log("actionId", actionId)
      }
      let url = `${environment.BOX_URL}/${box}/${actionId}`;

      //replace pagemodel with input values if pagemodel is pre binded to actionMap
      if(actionMap.pageModel){
        for(let obj in actionMap.pageModel[Object.keys(actionMap.pageModel)[0]]) {
          let widgetType = actionMap.pageModel[Object.keys(actionMap.pageModel)[0]][obj].widgetType
          let newWidget = WidgetManager.getWidget(widgetType)
          let widgetConfig = newWidget.getWidgetConfig();
          let valuePath = widgetConfig.valuePath
          let selectedWidget = actionMap.widgets.find(fn => fn.id == obj)
          Object.keys(selectedWidget).forEach(k => newWidget[k] = selectedWidget[k])
          let value = newWidget.getDeepObjectValue(newWidget, valuePath)
          actionMap.pageModel[Object.keys(actionMap.pageModel)[0]][obj].value = value
        }
        console.log("PAGE MODEL ", actionMap.pageModel);
      }

      let payload: any = await this.constructPayload(mapping, actionFunType, actionMap.inputParams, actionMap.pageModel, valueMap, actionMode, actionMap.origin);
      console.log("[API] Final payload: ", payload);
      console.log("[API] url: ", url);

      let httpOptions = { headers: {
          Authorization: `Bearer ${statelessToken}`,
          boxConfigToken: boxToken,
      }};

      actionResult = {
          status: "success"
      }

      var boxResponse = null;
      try {
          boxResponse = await this.boxService.execute(url, payload, null, null, httpOptions);
          console.log("boxResponse", boxResponse)
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: {
              message: actionMap?.successMessage || "Successfully executed the action",
              iconname: "check_circle",
            },
            duration: 3000,
            horizontalPosition: 'end',
          });
      } catch (err: any) {
          console.log("[EXE] Failed for Url", url, "ERROR : ", err)
          actionResult.status = "failure";
          this.openSnackBar({iconname: "error"}, err)
      }
      this.spinnerService.hide();
    }catch(e){
      console.error(e)
      this.openSnackBar({iconname: "error"}, e)
    }
    return actionResult;
  }

  async checkAndGetBoxAction(box, actionId, boxToken, mapping, inputParams){
    let attribute = await this.getPrimaryAttribute(box, actionId, boxToken, inputParams);
    // var result = 'create';

    let index = null;
    var clonedMapping = JSON.parse(JSON.stringify(mapping));
    for(var i = 0; i < clonedMapping.length; i ++){
      if(clonedMapping[i].appField?.__id == attribute?.__id){
        // result = 'update';
        index = i;
        break;
      }
    }

    if(index !== null) mapping.splice(index, 1)

    console.log("mapping after", mapping)
    return mapping;
  }

  async getPrimaryAttribute(box, actionId, boxToken, inputParams){
    let attributes
    try{
      attributes = await this.boxService.getAttributes(box, actionId, null, boxToken, inputParams);
    }catch(e){
      console.error("could not fetch attributes", e)
      this.openSnackBar({iconname: "error"}, e)
    }
    // console.log("attributes", attributes);
    var result = null;
    for(var i = 0; i < attributes.length; i++){
      if(attributes[i]?.primary){
        result = attributes[i];
      }
    }
    return result;
  }

  async getWhiteLabeledConnection(widget:any){
    let pageModel = JSON.parse(JSON.stringify(this.pageService.getPageModel()))
    pageModel = this.expressionUtility.resolvePageModelExpressions(pageModel);
    return this.actionServiceUtility.getObjectValue(widget.__id, pageModel);
  }

  async constructPayload(mapping: any, actionFunType: string, inputParams?: any, pagemodel?: any, valueMap?:any, actionMode?:any, origin?: any): Promise<any> {

    let payload: any;
    let pageModel
    if(valueMap) {
      pageModel = valueMap;
    } else {
      pageModel = JSON.parse(JSON.stringify(this.pageService.getPageModel()))
      if(pagemodel) if(Object.keys(pageModel).length === 0) pageModel = JSON.parse(JSON.stringify(pagemodel))
      pageModel = this.expressionUtility.resolvePageModelExpressions(pageModel)
    }

    pageModel = await this.contextDataService.getContextData(pageModel);
    console.log("[ApplicationAction construct payload()] pageModel", pageModel)
    let data = this.actionServiceUtility.prepareAllHash(mapping, pageModel);
    console.log("payload", data)

    // merge context data
    console.log("actionMode", actionMode)
    console.log("origin", origin)
    if((actionMode == "update" || actionMode == "save") && origin == "formpanel" && pageModel.old){
      Object.keys(pageModel.old).forEach(key => {
        if(!data.hasOwnProperty(key)){
          console.log("will add", key, "val", pageModel.old[key])
          data[key] = pageModel.old[key]
        }
      })
      console.log("data appended", data)
    }

    let te = new TemplateEngine();
    console.log("[ApplicationAction construct payload()] pageModel", pageModel)
    if(inputParams) {
      let convertedData = this.actionServiceUtility.convertDataWithDots(pageModel);
      inputParams = te.fillAny(inputParams, convertedData)
    }
    if (actionFunType == 'boxObject') {
      if(actionMode && actionMode == "deleteById"){
        payload = {
          parameters: data,
        };
      } else {
        data = this.actionServiceUtility.removeEmptyProperties(data);
        payload = {
          parameters: {
            data: [data],
            options: inputParams || {}
          },
        };
      }
    } else {
      payload = {
        parameters: data,
      };
    }
    return payload;
  }


  // prepareAllHash(fieldMap: string | any[], fieldValueMapObj: any) {
  //   console.log("mapping", fieldMap)
  //   console.log("values", fieldValueMapObj)
  //   let te = new TemplateEngine();
  //   let payload: any = {};

  //   for (let i = 0; i < fieldMap.length; i++) {
  //     let field = fieldMap[i];
  //     console.log("dealing field", field)
  //     let appField: string = field.appField.__id || field.appField.name;
  //     let mapType: string = field.mappingType;
  //     let sourceField: string = field.sourceField?.__id || field.sourceField;

  //     console.log("sourceField", sourceField)
  //     console.log("appField", appField)
  //     console.log("mapType", mapType)

  //     let resultValue: any
  //     if (mapType == 'templateField') {
  //       resultValue = te.fillAny(sourceField, fieldValueMapObj);
  //     } else {
  //       resultValue = this.getObjectValue(sourceField, fieldValueMapObj);
  //     }
  //     console.log("result value", resultValue)

  //     payload = this.fillIntoPayload(payload, appField, resultValue)
  //     console.log("payload after replace", JSON.parse(JSON.stringify(payload)))
  //     // console.log("appField", appField, payload[appField])
  //   }

  //   return payload;
  // }

  private fillIntoPayload(obj, path, value) {
    // console.log("")
    // console.log("obj", JSON.parse(JSON.stringify(obj, null, 2)))
    // console.log("path", path)
    // console.log("value", JSON.parse(JSON.stringify(value, null, 2)))

    let effectiveValue
    if(Array.isArray(value)){
      effectiveValue = JSON.parse(JSON.stringify(value))
    }else{
      effectiveValue = value
    }

    var schema = obj;  // a moving reference to internal objects within obj
    var pList = path.split('.');
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i];
        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem];
    }

    schema[pList[len-1]] = effectiveValue;
    return obj;
  }

  openSnackBar(snackBarObj: any, error: any){
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        title: error?.error?.error?.name || error.message || error || '',
        description: error?.error?.error?.message || error?.error?.error || error?.error?.message || '',
        recommendation: snackBarObj?.snackBarRecommendation || '',
        message: snackBarObj.snackBarMessage,
        iconname: snackBarObj.snackBarIcon,
        isError: true
      },
      duration: snackBarObj.snackBarDuration || undefined,
      horizontalPosition: 'end',
    });
  }

  getObjectValue(string: any, data: any) {
    console.log('string', string);
  }


}
