import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';
import { MetaService } from '../../services/meta-service';
import { PageService } from '../../services/page-service.service';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import { FormService } from 'src/app/form/form.service';
import { WidgetService } from '../../services/widget-service.service';


@Component({
  selector: 'app-widget-configuration',
  templateUrl: './widget-configuration.component.html',
  styleUrls: ['./widget-configuration.component.css'],
})
export class WidgetConfigurationComponent implements OnInit {

  //################ WIDGET META COMES AS DATA #########################
  widgetMeta: any;
  panelId: any;
  pageMeta: any;
  widgetSecurityEffects: any;

  isOpenDataPanel: boolean = false
  showSave: boolean = false;
  widgetConfigOpenSub: any;

  // @Input() inputData: any
  @Output() settingsChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    // public dialogRef: MatDialogRef<WidgetConfigurationComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private metaService: MetaService,
    private _snackbar: MatSnackBar,
    private pageService: PageService,
    private formService: FormService,
    private widgetService: WidgetService
  ) {
    
  }

  ngOnInit(): void {
    this.metaService.pageMeta.subscribe(pageMeta => {
      // console.log("[WIDGET CONFIGURATION] pageMeta from sub", pageMeta)
      this.pageMeta = pageMeta
    })

    this.widgetConfigOpenSub = this.widgetService.$openWidgetSidebarSettings.subscribe(data => {
      if(!data) return
      console.log("subscription caught in widget configuration component", data)
      this.init(data)
    })

    if(!this.pageMeta && this.formService.formMeta){
      this.pageMeta = this.formService.formMeta.value
    }
  }

  init(data){
    this.widgetMeta = data.widgetMeta
    this.panelId = data.panelId

    if(!this.widgetMeta?.type || !this.widgetMeta?.id) return
    this.widgetSecurityEffects = WidgetManager.getWidget(this.widgetMeta.type, this.widgetMeta.id, this.widgetMeta.name).getSecurityEffects();
    console.log("this.widgetSecurityEffects ", this.widgetSecurityEffects )
  }

  dataPanelOpened(flag: boolean) {
    console.log("data panel opened", this.widgetMeta?.connections)
    this.isOpenDataPanel = flag
    // this.showSave = false
  }
  displayPanelOpened() {
    console.log("display panel opened", this.widgetMeta)
    this.showSave = true
  }
  actionPanelOpened() {
    console.log("action panel opened", this.widgetMeta)
    this.showSave = true
  }
  securityPanelOpened() {
    console.log("action panel opened", this.widgetMeta)
    this.showSave = true
  }

  saveWidgetSpecifics(showSnack: boolean = true) {
    console.log("new widgetMeta:", JSON.parse(JSON.stringify(this.widgetMeta || "")))
    // console.log("will inject to panel:", this.panelId)

    // let pageMeta = this.pageService.pageMeta
    console.log("pageMeta", this.pageMeta)
    let panelIndex = this.pageMeta.panels?.findIndex(panel => panel.id == this.panelId)
    let panelMeta = this.pageMeta.panels[panelIndex]

    let matchedColId, matchedRowId, matchedElementIndex
    outerLoop: for (let i = 0; i < panelMeta?.layoutMap?.list?.length; i++) {
      let columnId = panelMeta.layoutMap.list[i]
      let column = panelMeta.layoutMap[columnId]
      // console.log("column", column)
      for (let j = 0; j < column?.list?.length; j++) {
        let rowId = column.list[j];
        let row = column[rowId]
        // console.log("row", row)
        for (let k = 0; k < row?.elements?.length; k++) {
          let element = row.elements[k];
          if(element?.id == this.widgetMeta.id){
            matchedColId = columnId
            matchedRowId = rowId
            matchedElementIndex = k
            // console.log("matched", element)
            break outerLoop
          }
        }
      }
    }

    if(matchedColId != undefined && matchedRowId != undefined && matchedElementIndex != undefined){
      panelMeta.layoutMap[matchedColId][matchedRowId].elements[matchedElementIndex] = JSON.parse(JSON.stringify(this.widgetMeta))
      this.pageMeta.panels[panelIndex] = panelMeta
    }
    console.log("pageMeta after replace", JSON.parse(JSON.stringify(this.pageMeta || "")))
    // let widgetIndex = this.pageMeta.panels?.[panelIndex]?.widgets.findIndex(w => w.id == this.widgetMeta.id)
    // this.pageMeta.panels[panelIndex].widgets[widgetIndex] = this.widgetMeta
    this.metaService.pageMeta.next(this.pageMeta)
    this.metaService.userMadeChanges.next(true)

    // this.metaService.update(this.pageMeta)
    // .then(res => {
    //   console.log("pageMeta updated", res)
    // })
    // .catch(err => {
    
    //   console.log("error in updating pageMeta", err)
    // })

    if(showSnack) {
      this._snackbar.openFromComponent(SnackbarComponent, { data: { iconname: 'info', message: "Configuration saved successfully!!" }, duration: 3000 })
    }
    this.settingsChanged.next(this.widgetMeta)
    // this.dialogRef.close(this.widgetMeta)
  }

  widgetMetaChange(event: any) {
    console.log("[widget configuration] widgetMetaChange", JSON.parse(JSON.stringify(event)))
    this.widgetMeta = event;
    this.saveWidgetSpecifics(false)

    let emitterdata = {
      actionType: 'updateStyles',
      data: event,
      widgetId: this.widgetMeta.id
    }
    this.metaService.setContextChanged(emitterdata) // caught by each widget individually
  }

}
