<div class="outerCover" *ngIf="builderMode" [ngClass]="{ selectedNow: selectedWidgetId == widgetMeta.id, hoveredNow: selectedWidgetId != widgetMeta.id && hoveredNow }"
  (mouseenter)="widgetMouseenter()" (mouseleave)="widgetMouseleave()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="selectedWidgetId !== widgetMeta.id && !hoveredNow"></widget-drag-handle>
  <div class="innerCover">
    <mat-form-field [class]="getFormFieldClass()"
      [appearance]="widgetMeta.config.appearance?.value"
      [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'">
      <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
      <textarea 
        (click)="onClick()" 
        matInput #input
        [ngStyle]="styles"
        [(ngModel)]="widgetMeta.config.textContent.value"
        [placeholder]="widgetMeta.config.placeholder.value" 
        [attr.rows]="rowsValue" (input)="emitUserInput()"
      ></textarea>
    </mat-form-field>
  </div>
</div>

<div class="outerCover" *ngIf="!builderMode && (!widgetMeta.config.hidden || !widgetMeta.config.hidden.value)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover" *ngIf="!widgetMeta.config.viewOnly || !widgetMeta.config.viewOnly.value">
    <mat-form-field floatLabel="{{widgetMeta?.config?.nofloat?.value ? 'never' : ''}}" class="input"
      (mousedown)="$event.stopPropagation()" fxFlexFill [class]="getFormFieldClass()"
      [appearance]="widgetMeta?.config?.appearance?.value"
      [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'">
      <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
      <textarea 
        matInput #input 
        [ngStyle]="styles"
        [(ngModel)]="widgetMeta.config.textContent.value" 
        [required]="widgetMeta.required ? widgetMeta.required : false"
        [placeholder]="widgetMeta.config.placeholder.value" 
        [attr.rows]="rowsValue" (input)="emitUserInput()"
      >
      </textarea>
      <mat-hint *ngIf="widgetMeta.note">
        <span style="font-size: small;margin-left: 10px; color: gray;" *ngIf="widgetMeta.note?.message">Note: {{widgetMeta.note.message}}</span>
        <a *ngIf="widgetMeta.note?.knowMoreLink" style="font-size: small;" [href]="widgetMeta.note.knowMoreLink" target="_blank"> &nbsp;know more</a>
      </mat-hint>
    </mat-form-field>
  </div>

  <div *ngIf="widgetMeta.config.viewOnly && widgetMeta.config.viewOnly.value"
    class="innerCover label-z-index content" [ngStyle]="styles">
    {{widgetMeta.config.textContent.value}}
  </div>
</div>
