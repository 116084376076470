import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TokenUtil } from '../core/services/TokenUtil.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as cryptoJs from 'crypto-js';
import { take } from 'rxjs/operators';
import { BoxInstance } from '../core/boxInstances/BoxInstance';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';

const DEFAULT_KEY = 'DuolcMroftalpSurvij';

@Injectable({
  providedIn: 'root',
})
export class ClientPlatformService {
  spinner: boolean = false;
  userEmail: string;
  clientUserId: string;
  clientInstanceId: string;
  photoUrl: string = null;
  displayName: string = null;
  preAuthenticatedToken: string;
  workSpaceId: any;
  workSpaces: any;
  workspaceObj: any;
  connectionsList: any;
  connectionsOfBox: any;
  connectionUrl: string = environment.SERVER_BASE_URL;
  allServices: any[] = [];
  allServiceTemplates: any[] = [];
  allMapping: any[] = [];
  isAutoMapping: boolean = false;

  connectionObjects: any = [];
  objectActions: any = [];
  objectAttributes: any = [];

  selectedConnection: any = {};
  //Edit service part
  selectedService: any = {};
  isCreateTemplatedService: boolean = false;
  isServiceEditable: boolean = false;
  isServiceSupportsObjectFunction: boolean = false;

  //form related info
  formId: string;
  clientType: string = '';
  sourceApp: string = '';
  sourceFieldIconMap: any = null;

  currentNavigateBackUrl : string  = '';
  hideConnectionsOnSidenav: boolean;

  catchErrorCallback = (err) => {
    let errMsg = `Error: ${JSON.stringify(err)}`;
    console.error(errMsg);
    // this.openCompSnackBar({ snackBarMessage: "Something went wrong! Please reach out to Jivrus support with error information." }, err)
    this.spinner = false;
    throw err?.error || { error: errMsg };
  }

  constructor(
    public http: HttpClient,
    private tokenUtil: TokenUtil,
    private _snackBar: MatSnackBar,
  ) {}

  // generate AES email set it into PreAuthenticatedToken
  getPreAuthenticatedToken() {
    let message = null;
    try {
      if(this.userEmail) {
        console.log('In email')
        message = this.userEmail;
      } else if(this.clientInstanceId) {
        console.log('In client Instance id');

        //client user id will be an email, it is only used in created or modified by field in workspace creation.
        let clientInstanceObj = {
          clientUserId: this.clientUserId,
          clientInstanceId: this.clientInstanceId
        };
        //if client instance then prefix with 'clientInstance:' so that we can find that workspace needs to be get by clientInstanceId.
        message = 'clientInstance:' + JSON.stringify(clientInstanceObj);
      }

      this.preAuthenticatedToken = cryptoJs.AES.encrypt(
        message,
        DEFAULT_KEY
      ).toString();

      console.log('Got preauthenticated token', this.preAuthenticatedToken);
    } catch (e) {
      console.error(e);
    }
  }

  decryptors(ciphertext: any) {
    var bytes = cryptoJs.AES.decrypt(ciphertext, DEFAULT_KEY);
    return bytes.toString(cryptoJs.enc.Utf8);
  }

  //get workspace _id from workspace api set it into workSpaceId
  async getWorkSpaceId(clientType, options?: any) {
    let url = `${this.connectionUrl}/workspace/${clientType}`;
    //check if query params is passed in options, if present construct query params for url.
    let queryParams = options?.queryParams;
    if (queryParams) {
      url += `?${Object.keys(queryParams).map((key) => `${key}=${queryParams[key]}`).join('&')}`
    }
    console.log("url",url);
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );
    console.log("headers",headers);

    try {
      let response: any = await this.http.get(url, { headers }).toPromise();
      console.log("response",response);
      this.workSpaces = response.data;
      this.workspaceObj = response.data[0];
      this.workSpaceId = response.data[0]['_id'];
    } catch (error) {
      console.error(error);
    }
  }

  async updateWorkspace(clientType, body) {
    console.log('Updating workspace', this.workSpaceId);
    if (!body._id) body._id = this.workSpaceId;

    let url = `${environment.SERVER_BASE_URL}/workspace/${clientType}`;

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    var promise = this.http.put(url, body, { headers }).toPromise();

    await promise
      .then(async (params: any) => {
        console.log('Updated workspace', params);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 4000 });
  }

  openErrorSnackBar(message: string) {
    this._snackBar.open(message, 'close');
  }

  openErrorActionSnackBar(message: string, action: string, actionCb: any, config: any = {}) {
    this._snackBar.open(message, action, config).onAction().pipe(take(1)).subscribe(actionCb);
  }

  openCompSnackBar(snackBarObj: any, errorMessage?: any) {
    const { snackBarMessage, snackBarIcon, snackBarDuration, snackBarRecommendation } = snackBarObj || {};
    let config: any = {
      data: {
        message: snackBarMessage,
        iconname: snackBarIcon || 'error',
      },
      duration: snackBarDuration || undefined,
      horizontalPosition: 'end',
    };

    if (errorMessage) {
      const error = errorMessage?.error?.error;
      const errorObj = {
        title: error?.name || '',
        description: error?.message || JSON.stringify(error) || errorMessage?.error?.message || '',
        recommendation: snackBarRecommendation || '',
        isError: true
      }
      config = {...config, data: {...config.data, ...errorObj} };
    }

    this._snackBar.openFromComponent(SnackbarComponent, config);
  }

  async getUserFromEmail(email: string) {
    console.log('Getting user %s details...', email);

    let url = `${environment.SERVER_BASE_URL}/user/${email}`;

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    var promise = this.http.get(url, { headers }).toPromise();

    var userObj = await promise
      .then(async (user: any) => {
        console.log('User', user);
        return user;
      })
      .catch((err) => {
        console.error('Error while fetching user details', err);
      });

    return userObj;
  }

  async getEvents(payload: any) {
    console.log('Getting eventss...', payload);

    let url = `${environment.SERVER_BASE_URL}/event/events/get`;

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    var promise = this.http.post(url, payload, { headers }).toPromise();

    var events = await promise
      .then(async (resp: any) => {
        console.log('User', resp);
        return resp.result?.data;
      })
      .catch((err) => {
        console.error('Error while fetching events', err);
      });

    console.log(events);

    return events;
  }

  async getFlowSummary(flowId: string) {
    console.log('Getting flow summary for %s...', flowId);

    let url = `${environment.SERVER_BASE_URL}/flow/summary/${flowId}`;

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    var promise = this.http.get(url, { headers }).toPromise();

    var summary = await promise
      .then(async (resp: any) => {
        console.log('[FLOW] Summary', resp);
        return resp.result?.data;
      })
      .catch((err) => {
        console.error('Error while fetching flow summary', err);
      });

    return summary;
  }

  async getBox(boxId: string) {
    this.spinner = true;
    let url = `${environment.BOX_URL}/${boxId}`;
    let token = await this.tokenUtil.getStatelessToken();
    const headers = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + token
    );
    var promise = this.http.get(url, { headers }).toPromise();

    var boxRes = await promise
      .then(async (box: any) => {
        console.log('Box', box);
        this.spinner = false;
        return box;
      })
      .catch((err) => {
        this.openCompSnackBar({snackBarMessage: "Error while fetching the box info"}, err);
        console.error('Error while fetching the box info:', err);
        this.spinner = false;
      });

    return boxRes;
  }

  async checkBoxSupportsObject(boxId: string) {
     let isObjectSupport = false;
     let box = await this.getBox(boxId);
     if(box?.supports?.includes('object')) {
        isObjectSupport = true;
     }
     return isObjectSupport;
  }

  //get connection table data from connection get all api
  async getAllConnections(filter?: any) {
    console.log('Getting all the connections.', this.userEmail);
    let url = `${environment.SERVER_BASE_URL}/connection/${this.workSpaceId}`;
    if(filter) url = url + "?filter=" + filter;

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    console.log("url:", url);

    var promise = this.http.get(url, { headers }).toPromise();

    var connections = await promise
      .then(async (conn: any) => {
        console.log('Connections here', conn);
        this.connectionsList = conn.data;
        return conn.data;
      })
      .catch((err) => {
        console.log(err);
      });

    return connections;
  }

  async getConnection(connId: string) {
    this.spinner = true;
    let url = `${environment.SERVER_BASE_URL}/connection/id/${connId}`;

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    var promise = this.http.get(url, { headers }).toPromise();

    var currConnection = await promise
      .then(async (conn: any) => {
        console.log('Conn', conn);
        const connStatus = conn.data?.status;
        this.spinner = false;
        if (connStatus === "DRAFT" || connStatus === "FAILED") {
          throw "Connection not established, please authorize the connection and proceed";
        }
        return conn.data;
      })
      .catch(this.catchErrorCallback);

    return currConnection;
  }

  async getConnectionsOfBox(boxId: string) {
    console.log('Get connections of box', boxId);
    let url = `${environment.SERVER_BASE_URL}/connection/${this.workSpaceId}/connections/${boxId}`;

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    var promise = this.http.get(url, { headers }).toPromise();

    await promise
      .then(async (params: any) => {
        console.log('[Box] Connections', params);
        this.connectionsOfBox = params.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async saveService(service, options?: any) {
    let url = `${environment.SERVER_BASE_URL}/service`;

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    await this.http
      .post(url, service, { headers })
      .toPromise()
      .then((res: any) => {
        console.log('SERVICE RESP', res);
        if (res.data) {
          let respData = res.data[0];
          if (options?.servicePosition) {
            this.allServices.splice(options.servicePosition, 0, respData);
          } else {
            this.allServices.push(respData);
          }
          //persist the service if it is edit flow.
          if (options?.isEditFlow) {
            this.editServiceDataTransfer(respData)
          } else {
            this.openSnackBar(options?.snackbarMessage || 'Service saved successfully.');
          }
        }
      })
      .catch((err) => console.log(err));
  }

  async editService(service, serviceId, options?: any) {
    let url = `${environment.SERVER_BASE_URL}/service/${serviceId}`;

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    await this.http
      .put(url, service, { headers })
      .toPromise()
      .then((res: any) => {
        console.log('Service Updated!', res);
        //getting the index of updating service.
        let serviceIndex = this.allServices.map((serv => serv._id)).indexOf(serviceId);
        console.log('SERVICE INDEX', serviceIndex, serviceId)
        //replace the updated service in the all services array.
        if (res.data) this.allServices.splice(serviceIndex, 1, res.data[0]);
        if (!options?.isEditFlow) {
          this.openSnackBar('Service updated successfully.');
        }
      })
      .catch((err) => console.log(err));
  }

  async getAllServices() {
    let url = `${environment.SERVER_BASE_URL}/service/${this.workSpaceId}`;

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    await this.http
      .get(url, { headers })
      .toPromise()
      .then((resp: any) => {
        console.log('ALL SERVICES', resp);
        this.allServices = resp.data || [];
      })
      .catch((err) => console.log(err));
  }

  //gets filtered services, it supports passing custom defined query filters as payload.
  async getFilteredServicesNew(payload: any) {
    let url = `${environment.SERVER_BASE_URL}/service/query/get`;

    console.log(`URL: ${url}, Payload: ${JSON.stringify(payload)}`);

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    await this.http
      .post(url, payload, { headers })
      .toPromise()
      .then((resp: any) => {
        console.log('ALL SERVICES', resp);
        this.allServices = resp.data || [];
      })
      .catch((err) => console.error(err));
  }

  //This function get filtered services
  async getFilteredServices(clientType : string, moduleName : string) {
    let url = `${environment.SERVER_BASE_URL}/service/client-platform/${this.workSpaceId}/${clientType}/${moduleName}`;

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    await this.http
      .get(url, { headers })
      .toPromise()
      .then((resp: any) => {
        console.log('ALL SERVICES', resp);
        this.allServices = resp.data || [];
      })
      .catch((err) => console.log(err));
  }

  async getServiceTemplates(clientType: string, boxId: string) {
    let url = `${environment.SERVER_BASE_URL}/service/query/get`;

    let payload = {
      "filter": `service_map.host_app=${clientType}|string,service_map.box_app=${boxId}|string,options.template_map.access=public;workspace_id=${this.workSpaceId}|string,options.isTemplate=true|boolean`
    }

    console.log(`URL: ${url}, Payload: ${JSON.stringify(payload)}`);

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    try {
      let resp: any = await this.http.post(url, payload, { headers }).toPromise();
      console.log('[SERVICE-TEMPLATES]', resp);
      this.allServiceTemplates = resp.data;
      return resp.data;
    } catch (error) {
      console.log("Error while getting service templates", error);
    }
  }


  async deleteService(serviceId: string): Promise<boolean> {
    console.log('Going to delete service ' + serviceId);
    const url = `${environment.SERVER_BASE_URL}/service/${serviceId}`;
  
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );
  
    try {
      await this.http.delete(url, { headers }).toPromise();
      console.log(`Service ${serviceId} deleted!`);
      // Optionally get the latest services
      // await this.getAllServices();
      this.openSnackBar('Service deleted successfully!');
      return true; // Indicate success
    } catch (err) {
      console.error(err);
      this.openSnackBar('Error deleting service. Please try again.'); // Show error snackbar
      return false; // Indicate failure
    }
  }
  

  // async deleteService(serviceId: string) {
  //   console.log('Going to delete service ' + serviceId);
  //   let url = `${environment.SERVER_BASE_URL}/service/${serviceId}`;

  //   const headers = new HttpHeaders().set(
  //     'Authorization',
  //     'PreAuthenticatedToken ' + this.preAuthenticatedToken
  //   );

  //   await this.http
  //     .delete(url, { headers })
  //     .toPromise()
  //     .then(async (resp: any) => {
  //       console.log(`Service ${serviceId} deleted!`);
  //       //get the latest services
  //       // await this.getAllServices();
  //       // this.openSnackBar('Service deleted successfully!');
  //     })
  //     .catch((err) => console.error(err));
  // }

  async executeBoxFn(connection: any, payload: any, action: string) {
    this.spinner = true;
    //if connection Id passed fetch the connection object.
    if (typeof connection == 'string') {
      connection = await this.getConnection(connection);
    }
    const { box_id, box_token } = connection;
    const endpoint = `/${box_id}/${action}`;
    const baseUrl = await BoxInstance.getInstance(endpoint);
    const url = baseUrl + endpoint;

    console.log('[EXE-BOX-FN] Url %s, Payload %s', url, JSON.stringify(payload));
    const response = await this.execute(url, box_token, payload);
    return response;
  }

  async execute(url: string, boxToken: string, payload?: any) {
    const token = this.tokenUtil.getStatelessToken();

    const headers = new HttpHeaders()
      .set("boxConfigToken", boxToken)
      .set("Authorization", `Bearer ${token}`)
      .set("Content-Type", "application/json");

    const promise = this.http.post(url, payload, { headers }).toPromise();

    const response = await promise
    .then((res: any) => {
      console.log('[EXECUTE] Result', res);
      this.spinner = false;
      return res;
    })
    .catch(this.catchErrorCallback);

    return response;
  }

  async getConnectionObjects(event: any, apiFetch?: boolean) {
    this.spinner = true;
    let token = this.tokenUtil.getStatelessToken();

    //get the updated connection.
    var connection = await this.getConnection(event._id);

    const headers = new HttpHeaders()
      .set('boxConfigToken', connection.box_token)
      .set('Authorization', 'Bearer ' + token)
      .set("Content-Type", "application/json" );;

    var endpoint = `/${connection.box_id}/objects`;
    let url = await BoxInstance.getInstance(endpoint);
    var connObjects = await this.http
      .get(url + endpoint, { headers })
      .toPromise()
      .then((response: any) => {
        this.spinner = false;
        console.log('OBJECT RES', response.objects);
        //return if it is direct API fetch, do not persist.
        if(apiFetch) return response.objects;

        this.connectionObjects = response.objects;
      })
      .catch(this.catchErrorCallback);

      return connObjects;
  }

  // action: action,
  //       getAttributesFunction: this.actions.find(
  //         (fn) => fn.__id == 'getattributes'
  //       ),

  async getObjectActions(event: any, connection: any, apiFetch?: boolean) {
    this.spinner = true;
    console.log('OBJ EVENT', event);
    var boxObjectId = event && event.__id ? event.__id : event.name;

    //get the updated connection.
    var currConnection = await this.getConnection(connection._id);

    var token = this.tokenUtil.getStatelessToken();
    var endpoint = `/${currConnection.box_id}/${boxObjectId}/functions`;
    let configUrl = await BoxInstance.getInstance(endpoint);

    const headers = new HttpHeaders()
      .set('boxConfigToken', currConnection.box_token)
      .set('Authorization', 'Bearer ' + token)
      .set("Content-Type", "application/json" );;

    var actions = await this.http
      .get(configUrl + endpoint, { headers })
      .toPromise()
      .then((resp: any) => {
        console.log('[Actions]', resp);
        //return if it is direct API fetch, do not persist.
        let objActions = resp.functions.filter(
          (func: any) => func.dataFlow == 'push'
        );

        if(apiFetch) return objActions;

        this.objectActions = objActions;
        this.spinner = false;
      })
      .catch((err) => {
        console.error('Error in getting object actions', err);
        this.spinner = false;
      });

    return actions;
  }

  async getObjectAttributes(event: any, connection: any, attributeOptions?: any) {
    this.spinner = true;
    console.log('OBJ ATTR EVENT', event, connection);
    var boxObjectId = event && event.__id ? event.__id : event.name;
    var payload = { parameters: { object: boxObjectId, options: {} } };

    if (attributeOptions && attributeOptions.length) {
      attributeOptions.forEach(option => {
        payload.parameters.options[option.__id] = option.value
      });
    }

    //get the updated connection.
    var currConnection = await this.getConnection(connection._id);

    var token = this.tokenUtil.getStatelessToken();
    var endpoint = `/${currConnection.box_id}/getattributes`;
    let fieldsUrl = await BoxInstance.getInstance(endpoint);
    const headers = new HttpHeaders()
      .set('boxConfigToken', currConnection.box_token)
      .set('Authorization', 'Bearer ' + token)
      .set("Content-Type", "application/json" );

    var attributes = await this.http
      .post(fieldsUrl + endpoint, payload, { headers })
      .toPromise()
      .then((resp: any) => {
        console.log('ATTR', resp);
        if (!event.cancelSave) this.objectAttributes = resp.result;
        this.spinner = false;
        return resp.result;
      })
      .catch((err) => {
        console.error('Error in getting attributes details', err);
        this.spinner = false;
      });

    return attributes;
  }

  constructAppFieldsForBoxFn(input: any){
    let fields = [];
    for (let attribute of input.list) {
      fields.push(input[attribute])
    }
    //set it inside object attributes.
    this.objectAttributes = fields;
    return fields;
  }

  async getBoxFunctions(connection: any) {
    this.spinner = true;
    console.log('Getting box functions', connection);

    //get the updated connection.
    var currConnection = await this.getConnection(connection._id);

    var token = this.tokenUtil.getStatelessToken();
    var endpoint = `/${currConnection.box_id}/functions`;
    let configUrl = await BoxInstance.getInstance(endpoint);

    const headers = new HttpHeaders()
      .set('boxConfigToken', currConnection.box_token)
      .set('Authorization', 'Bearer ' + token)
      .set("Content-Type", "application/json" );

    var boxFunctions = await this.http
      .get(configUrl + endpoint, { headers })
      .toPromise()
      .then((resp: any) => {
        console.log('[Box Functions]', resp);
        this.spinner = false;
        return resp.functions;
      })
      .catch((err) => {
        this.openCompSnackBar({ snackBarMessage: "Error in getting box functions" }, err)
        console.error('Error in getting box functions', err);
        this.spinner = false;
      });

    return boxFunctions;
  }

  async handleWebhook(eventId: string, connection: any, action: string, payload: any) {
    if (typeof connection == "string") {
      connection = await this.getConnection(connection);
    }
    const { box_id, box_token } = connection;
    const endpoint = `/${box_id}/event/${eventId}/${action}`;
    const baseUrl = await BoxInstance.getInstance(endpoint);
    const url = baseUrl + endpoint;
    console.log('[WEBHOOK] Url %s, Payload %s', url, JSON.stringify(payload));
    const webhookResp = await this.execute(url, box_token, payload);
    return webhookResp?.result?.data;
  }

  async getBoxObjectData(connection: any, boxObjectId: string, payload: any, options?: any) {
    console.log('Getting box object data', connection, payload);

    //get the updated connection.
    var currConnection = await this.getConnection(connection._id);

    var token = this.tokenUtil.getStatelessToken();
    var endpoint = `/${currConnection.box_id}/${boxObjectId}/get`;
    let configUrl = await BoxInstance.getInstance(endpoint);
    //get the userId from user endpoint
    var user = await this.getUserFromEmail(this.userEmail || this.clientUserId);

    let headers = new HttpHeaders()
      .set('boxConfigToken', currConnection.box_token)
      .set('Authorization', 'Bearer ' + token)
      .set('Cache-Control', 'no-cache')
      .set("Content-Type", "application/json" );

    //set the eventtoken in headers to count data unit
    if(this.workSpaceId && user && options) {
      console.log(`Event ${this.workSpaceId}:${user._id}:${options.initiatorId}:${options.initiatorType}`);
      headers = headers.append('eventtoken', `Event ${this.workSpaceId}:${user._id}:${options.initiatorId}:${options.initiatorType}`);
    }

    console.log('headers', headers);

    var boxObjectData = await this.http.post(configUrl + endpoint, payload, { headers }).toPromise();

    return boxObjectData;
  }

  async executeServiceInWorkspace(path: string, payload: any) {
    this.spinner = true;
    console.log('Executing service...', this.workSpaceId);
    console.log('Payload', payload);

    let url = `${environment.SERVER_BASE_URL}/execution/${path}`;

    console.log('[EXE] Url', url);

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.preAuthenticatedToken
    );

    var promise = this.http.post(url, payload, { headers }).toPromise();

    var results = await promise
      .then((res: any) => {
        console.log('[EXE] Service Result', res);
        this.spinner = false;
        return res;
      })
      .catch((err) => {
        console.error("Failed to execute service:", err);
        this.openCompSnackBar({ snackBarMessage: "Failed to execute service" }, err)
        this.spinner = false;
      });

      return results;
  }

  checkIsObjectFnSupported(boxFunctions: any) {
    //check whether the objects function is present or not, if then only get the objects.
    let isObjectFnSupportedResp = boxFunctions.find((func: any) => func.__id == 'getobjects');
    let isObjectFunctionSupported = (isObjectFnSupportedResp) ? true : false;
    this.isServiceSupportsObjectFunction = isObjectFunctionSupported;
    return isObjectFunctionSupported;
  }

  /**
   * checks options to collect and returns the options array
  */
  checkIsAtrributesOptionInputsRequired(fn: any) {
    console.log("function", fn)
    if (!fn) return null
    let options: any[] = []
    if (
      fn.input.list.find(input => input == 'options')
      && Array.isArray(fn.input['options']['list'])
      && fn.input['options']['list'].length
    ) {
      console.log("options exists inside input")
      fn.input['options']['list'].forEach((optionItem: string) => {
        fn.input['options'][optionItem]['value'] = fn.input['options'][optionItem]['defaultValue'] || ''
        options.push(fn.input['options'][optionItem])
      });
      console.log("options to collect for function", fn.__id, options)
      return options
    } else {
      return null
    }
  }

  editServiceDataTransfer(service: any, isCreateTemplatedService? : boolean) {
    this.selectedService = service;
    if(isCreateTemplatedService) {
      this.isCreateTemplatedService = true;
      this.selectedService.options.isTemplate = false;
    }
    this.isServiceEditable = true;
  }
}
