<div
  *ngIf="builderMode"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <!-- [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" -->
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover">
    <!-- <mat-form-field style="width: 100%;">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" (dateChange)="inputDetected($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field> -->
    <mat-form-field fxFlexFill>
      <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
      <input matInput type="date" [ngModel]="displayValue" [placeholder]="widgetMeta.config.placeholder.value" [required]="widgetMeta?.required ? widgetMeta.required : false" (input)="inputDetected($event)">
    </mat-form-field>
  </div>
</div>

<div
  *ngIf="!builderMode && !widgetMeta.config?.hidden?.value" class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover">
    <mat-form-field class="input" *ngIf="!(widgetMeta.config.viewOnly?.value)" style="width: 100%;">
      <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
      <input matInput [matDatepicker]="picker" [required]="widgetMeta?.required ? widgetMeta.required : false" (dateChange)="inputDetected($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <div *ngIf="widgetMeta.config.viewOnly?.value">{{ displayValue || "" }}</div>
  </div>
</div>
