<spinner *ngIf="spinner" [center]="true" [selfSpin]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<div class="mat-typography">
  <div fxLayout="row" *ngIf="replacedStepMap?.length > 0">
    <div fxLayout="column" fxFlex="100">
      <div fxLayout="row" fxLayoutAlign="start center">
        Map your connections
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="5"></div>
        <div fxFlex="30">Apps</div>
        <div fxFlex="65">Your connections</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" *ngFor="let step of replacedStepMap; let i = index">
          <div fxFlex="5">
            <img class="boxLogo" [src]="step.boxMap.logo" alt="">
          </div>
          <div fxFlex="30">{{ step.boxMap.name }}</div>
          <div fxFlex="65">
            <app-connection-selection
              [config]="{'box_id': step.box_id}"
              (selectedConnection)="connectionSelected($event, step)"
            ></app-connection-selection>
        </div>
      </div>
      <div fxLayout fxLayout="row" fxLayoutAlign="start center">
        <button mat-raised-button color="primary"
        (click)="cloneObjectNow()" *ngIf="isObjectCreationRequired" matTooltip="Create required configuration">Setup schema</button>
      </div>
    </div>
  </div>
  <div fxLayout="row" *ngIf="replacedStepMap?.length == 0">
    <div fxLayout="column" fxFlex="100">
      <div fxLayout="row" fxLayoutAlign="start center">
        You are all set.
      </div>
    </div>
  </div>

</div>




