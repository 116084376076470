<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<div>
<div class="builder-toolbar">
    <app-builder-toolbar (action)="toolbarAction($event)" [flow]="flowMap"></app-builder-toolbar>
</div>

<div>

<div fxLayout="row">
    <div fxFlex="70">
        <mat-tab-group animationDuration="1000ms" mat-align-tabs="center" (selectedTabChange)="tabChanged($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon style="margin-right: 1rem;">edit_document</mat-icon>
                    Draft
                </ng-template>
                <!-- class="draft-canvas-wrap container-with-thin-scrollbar" -->
                <div  class="draft-canvas-wrap container-with-thin-scrollbar">
                <div #zoomableElement class="zoomable-container" >
                  <div *ngIf="ready" >
                    <div style="height: 1.5rem;"></div>

                    <div fxLayoutAlign="center center" *ngIf="!isEditFlow">
                        <mat-accordion fxFlex="60">
                            <mat-expansion-panel [expanded]="generalInfoOpen" (opened)="generalInfoOpen = true; triggerConfigOpen = false" (closed)="generalInfoOpen = false">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                  General
                                </mat-panel-title>
                                <mat-panel-description>
                                    Name, description and trigger selection
                                </mat-panel-description>
                              </mat-expansion-panel-header>

                              <div fxLayout="row wrap" fxLayoutAlign="center center">
                                <div fxLayout="column" fxFlex="5"></div>
                                <div fxLayout="column" fxFlex="90">
                                    <ng-template [ngTemplateOutlet]="triggerSelection"></ng-template>
                                </div>
                                <div fxLayout="column" fxFlex="5"></div>
                            </div>

                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                    <!-- <br/>
                    <div fxLayoutAlign="center center" *ngIf="!isEditFlow">
                        <mat-accordion fxFlex="60">
                            <mat-expansion-panel [expanded]="triggerConfigOpen" (opened)="triggerConfigOpen = true; generalInfoOpen = false" (closed)="triggerConfigOpen = false">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                  Trigger ({{selectedTrigger.title}})
                                </mat-panel-title>
                                <mat-panel-description>
                                  Configure {{selectedTrigger.title}} details for the flow
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              <ng-template [ngTemplateOutlet]="triggerSettingBlock"></ng-template>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div> -->


                    <div fxLayoutAlign="center center" *ngIf="isEditFlow">
                      <ng-template [ngTemplateOutlet]="triggerSectionCard"></ng-template>
                    </div>
                    <br/>
                    <div (mouseenter)="addDummyStep(0)">
                        <div *ngIf="selectedTrigger" class="line" style="margin-bottom: -15px;"></div>
                        <div *ngIf="selectedTrigger" fxLayout="row" fxLayoutAlign="center center">
                            <button style="cursor: pointer;" color="primary" mat-icon-button color="primary" [matMenuTriggerFor]="menu"
                                matTooltip="Create Step">
                                <mat-icon>
                                    add_circle_outline
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                    <mat-menu #menu="matMenu">
                        <button (click)="addNewStep(0, 'action')" mat-menu-item>
                            <span>Action</span>
                        </button>
                        <button (click)="addNewStep(0, 'control')" mat-menu-item>
                            <span>Control</span>
                        </button>
                        <button (click)="addNewStep(0, 'processor')" mat-menu-item>
                          <span>Processor</span>
                        </button>
                    </mat-menu>
                    <div *ngIf="selectedTrigger && loadedFlow.steps && loadedFlow.steps.length > 0" class="line-bottom"
                      style="margin-top: -15px;margin-bottom: -15px;"></div>
                    <ng-template [ngTemplateOutlet]="flowsteps"></ng-template>
                  </div>
                </div>
                </div>
            </mat-tab>


            <mat-tab [disabled]="!publishedFlow || !isPublishedFlowLoaded">
                <ng-template mat-tab-label>
                    <mat-icon style="margin-right: 1rem;">verified</mat-icon>
                    Published
                </ng-template>

                <div *ngIf="!publishedFlow">
                    <spinner [selfSpin]="true" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
                </div>
                <div  class="draft-canvas-wrap container-with-thin-scrollbar">
                <div #zoomableElement class="zoomable-container" >
                  <div *ngIf="publishedFlow" >
                    <div style="height: 1.5rem;"></div>
                    <div fxLayoutAlign="center center">
                      <ng-template [ngTemplateOutlet]="triggerSectionCard"></ng-template>
                    </div>
                    <br/>
                    <div class="line-bottom"
                      style="margin-top: -15px;margin-bottom: -15px;"></div>
                    <ng-template [ngTemplateOutlet]="flowsteps"></ng-template>
                  </div>
                </div>
                </div>

            </mat-tab>
        </mat-tab-group>
    </div>
    <div fxFlex="30"style="border-left: 1px solid gainsboro;">

      <!-- draft toolbar -->
      <div *ngIf="editMode">
        <div  fxLayoutAlign="end end" fxLayout="row" class="flow-toolset-bar">
          <button  class="runbutton" mat-stroked-button color="primary" aria-label=""
          (click)="isShowExecute = !isShowExecute;isFlowConfigure = false;isFlowNameChange = false">
            <mat-icon *ngIf="!isShowExecute">play_arrow</mat-icon>
            <mat-icon *ngIf="isShowExecute">arrow_back</mat-icon>
            <span style="text-wrap: nowrap;">{{isShowExecute ? 'Back': 'Test Flow'}}</span>
          </button>
        </div>
        <mat-divider></mat-divider>
      </div>
      <!-- draft toolbar ends-->

      <!-- published toolbar -->
      <div *ngIf="!editMode">
        <div class="flow-toolset-bar" fxLayout="row" fxLayoutAlign="flex-end center">
          <mat-slide-toggle *ngIf="publishedFlow" class="active_toggle" style="margin-left: 30px; margin-top: 5px;"
          [checked]="publishedFlow.isActive" (change)="activeToggleChanged($event)"
          [matTooltip]="publishedFlow.isActive ? 'Click to make the flow Inactive' : 'Click to make the flow Active'">
          </mat-slide-toggle>

          <button mat-stroked-button color="primary" class="runbutton" (click)="isShowExecute = true">
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-icon>play_arrow</mat-icon>
              <!-- <mat-icon *ngIf="!isShowExecute">arrow_back</mat-icon> -->
              <span style="text-wrap: nowrap;">{{'Execute'}}</span>
            </div>
          </button>
          <button mat-stroked-button color="primary" class="runbutton" (click)="navigateToExecutionPage()">
            <div fxLayout="row" fxLayoutAlign="center center">
                <span><mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center" style="margin-right: .3em;">timeline</mat-icon></span>
                <span>Executions</span>
            </div>
          </button>
        </div>
        <mat-divider></mat-divider>
      </div>
      <!-- published toolbar ends-->
      <!-- side bar starts-->
        <flow-execution [editFlow]="flowMap" [publishedFlow]="publishedFlow"
          [isEdit]="editMode"
          *ngIf="isShowExecute && !isFlowConfigure"></flow-execution>

        <flow-builder-tools *ngIf="editMode && !isShowExecute && !isFlowConfigure && !isFlowNameChange && !isFlowTriggerConfigure"></flow-builder-tools>
        <flow-configuration #flowConfigurationComponent *ngIf="isFlowConfigure && !isShowExecute"
          [editMode]="editMode"
          (onConfigure)="setStepConfigure($event)"
          (stepSourceFields)="setSourceFieldsConfigure($event)"
          (actionChanged)="stepActionChangedConfigure($event)"
        ></flow-configuration>

        <flow-trigger-config *ngIf="!isFlowNameChange && !isFlowConfigure && isFlowTriggerConfigure && !isShowExecute"
          [editMode]="editMode"
          [selectedTrigger]="selectedTrigger"
          [flow]="isShowExecute ? publishedFlow : flowMap"
          (appSelected)="appSelected($event)"
          (sourceFields)="sourceFields = $event; setSourceFields(0, $event)"
          (onConfigure)="loadedFlow.trigger = $event"
          (saveSetup)="insertOrUpdateFlow($event)"
          (onSelectTrigger)="onSelectedTrigger($event)"
          >
        </flow-trigger-config>


        <flow-name-config *ngIf="!isShowExecute && !isFlowConfigure && isFlowNameChange && !isShowExecute"
        [editMode]="editMode"
        [flow]="isShowExecute ? publishedFlow : flowMap"
        ></flow-name-config>
        <!-- side bar starts-->
    </div>
</div>

<br/><br/><br/>

<ng-template fxLayoutAlign="center center" #triggerSectionCard  >
  <div fxLayout="column" fxFlex="20"></div>
  <div fxLayout="column" fxFlex="60">
    <mat-card class="step-card" [ngClass]="{'selected-card': isFlowTriggerConfigure}" appearance="outlined"
    (click)="editTrigger()">
    <!-- <div class="ribbon-event">
      Trigger
    </div> -->
      <mat-card-content style="padding: 1px;">
        <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: -20px;">
              <div fxLayout="column" fxFlex="2"></div>
              <div  fxLayout="column" fxFlex="13">
                <div class="custom-chip brown-chip">Trigger</div>
              </div>
              <div fxLayout="column" fxFlex="79"></div>
              <div fxLayout="column" fxFlex="10">
                <button *ngIf="editMode" style="cursor: pointer;"  (click)="$event.stopPropagation(); editTrigger()"
                        mat-icon-button color="primary"  matTooltip="Edit Trigger">
                  <mat-icon >edit</mat-icon>
                </button>
              </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: -10px;">
              <!-- <div fxLayout="column" fxFlex="5"></div> -->
              <div fxLayout="column" fxFlex="2"></div>
              <div  fxLayout="column" fxFlex="88">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <img
                  *ngIf="loadedFlow.trigger.box_logo_url && loadedFlow.trigger_type == 'app_event'"
                  [src]="loadedFlow.trigger.box_logo_url"
                  class="image-size"
                />
                <mat-icon
                  *ngIf="loadedFlow.trigger_type == 'schedule'"
                  class="icon-size-list"
                  >schedule</mat-icon
                >
                <mat-icon
                  *ngIf="loadedFlow.trigger_type == 'webhook'"
                  class="icon-size-list"
                  >webhook</mat-icon
                >
                <h4>{{selectedTrigger?.title}}</h4>
                </div>
              </div>
              <div  fxLayout="column" fxFlex="5">
            </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxLayout="column" fxFlex="20"></div>
</ng-template>

<ng-template #triggerSelection>
    <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center" *ngIf="editMode">
            <h2>{{ editMode ? "Tell us what triggers this flow" : "Selected trigger" }}</h2>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="100%" *ngIf="editMode" [class.disabled]="!editMode">
            <div *ngFor="let trigger of triggers;">
                <mat-card class="step-card" (click)="onSelectTrigger(trigger)" appearance="outlined"
                    class="card-1 mat-card-custom"
                    [ngClass]="trigger.id === loadedFlow.trigger_type ? 'card-1-selected' : ''"
                >
                    <mat-card-content fxLayout="column" >
                        <div fxLayoutAlign="center center">
                            <div class="trigger-title">{{trigger.title}}</div>
                        </div>
                        <div style="height: 1rem;"></div>
                        <div fxLayoutAlign="center center">
                            <mat-icon color="primary" class="trigger-icon"> {{trigger.icon}}</mat-icon>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <br />
        </div>

        <div *ngIf="editMode">
        <div fxLayout="row" style="padding-top: 6px;" fxLayoutAlign="center"
            *ngIf="loadedFlow.trigger_type === 'schedule'">
            <h3>Configure a schedule to trigger your automation flow</h3>
        </div>
        <div fxLayout="row" style="padding-top: 6px;" fxLayoutAlign="center"
            *ngIf="loadedFlow.trigger_type === 'webhook'">
            <h3>Configure a HTTP Webhook resource to trigger your automation flow</h3>
        </div>
        <div fxLayout="row" style="padding-top: 6px;" fxLayoutAlign="center"
            *ngIf="loadedFlow.trigger_type === 'app_event'">
            <h3>Configure an Application Event to trigger your automation flow</h3>
        </div>
        </div>
        <!-- triggerConfigOpen = true;generalInfoOpen = false; await  -->
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!isEditFlow && editMode" style="padding-bottom: 10px;">
          <button mat-stroked-button (click)="firstPublish()"
           color="primary">Next <mat-icon>arrow_forward</mat-icon></button>
        </div>
    </div>
</ng-template>

<ng-template #triggerSettingBlock>
    <div *ngIf="selectedTrigger" [class.disabled]="!editMode" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <app-trigger-event *ngIf="loadedFlow.trigger_type === 'app_event'"
                [flowMap]="loadedFlow"
                (sourceFields)="sourceFields = $event; setSourceFields(0, $event)"
                (onConfigure)="loadedFlow.trigger = $event"
                (appSelected)="appSelected($event)"
            ></app-trigger-event>
            <app-trigger-scheduler *ngIf="loadedFlow.trigger_type === 'schedule'"
                [flowMap]="loadedFlow"
                (onConfigure)="loadedFlow.trigger.schedulers = $event"
                (sourceFields)="sourceFields = $event; setSourceFields(0, $event)">
            </app-trigger-scheduler>
            <app-trigger-webhook *ngIf="loadedFlow.trigger_type === 'webhook'"
                [flowMap]="loadedFlow"
                (onConfigure)="loadedFlow.trigger = $event"
                (sourceFields)="sourceFields = $event; setSourceFields(0, $event)"
                (saveSetup)="insertOrUpdateFlow(true)">
            </app-trigger-webhook>

        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>
</ng-template>

<ng-template #flowsteps>
    <div *ngIf="loadedFlow.steps && loadedFlow.steps.length > 0" fxLayout="row wrap" fxLayout.xs="column"
        fxLayoutAlign="center center">
        <div fxLayout="column" fxFlex="20"></div>
        <div fxLayout="column" fxFlex="60">
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div *ngFor="let step of loadedFlow.steps; let i = index">

                    <!-- PLACEHOLDER STEP TO SHOW DROP POSITION -->
                    <div *ngIf="step.step_type === 'placeholder'" (mouseup)="this.handleCreateTool()" fxLayoutAlign="center center" class="placeholder-step">
                        <div style="text-align: center; color: #444; width: 100%">Drop here</div>
                    </div>

                    <!-- REGULAR FLOW STEPS (click)="expandStep(i)"
                     [ngStyle]="{'height': activeStepIndex === i ? 'auto' : '70px'}" -->
                    <div
                        *ngIf="!step.control_step && step.step_type !== 'placeholder'"
                        [ngStyle]="step.intentMap?.isIntent && !step.intentMap?.parentIntent ? { 'width': '90%', 'margin-left': '5%'} : {}"
                        [ngClass]="{'selected-card': activeStepIndex === i}"
                        class="step-container"  (click)="expandStep(i);selectedConfigureFlow(step, i)"
                    >
                        <mat-card class="step-card" id="step-{{i}}" appearance="outlined">
                          <div fxLayoutAlign="start" *ngIf="currentRunningStep == i && !fs.flowExecuted"
                          >
                            <mat-spinner color="warn" class="stepSpinner"  [diameter]="20"></mat-spinner>
                          </div>
                            <!-- <div class="ribbon-action">
                                {{step.step_type}}
                            </div> -->

                            <mat-card-content style=" padding: 1px;">
                              <div fxLayout="row" fxLayoutAlign="start center" style="padding-bottom: 2px;">
                                <!-- <div fxLayout="column" fxFlex="10">
                                    <mat-icon *ngIf="editMode" class="grabbable" style="color: grey" cdkDragHandle>
                                      drag_indicator
                                    </mat-icon>
                                </div> -->
                                <div fxLayout="column" fxFlex="5"  fxLayoutAlign="center center" style="font-weight: bold;color: #444"> {{i+1}} </div>
                                <div fxFlex="20"  fxLayoutAlign="center start">
                                  <div class="custom-chip green-chip">{{step.step_type| titlecase}}</div>
                                </div>
                                <div fxLayout="column" fxFlex="75" fxLayoutAlign="center start">
                                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
                                    <mat-icon fontIcon="stop" color="accent" style="margin-bottom: 10px;" matTooltip="Loop ends with this step" *ngIf="step.intentMap?.isIntentRemoved" >
                                      </mat-icon>

                                    <mat-icon fontIcon="start" color="accent" matTooltip="Step loop starts" *ngIf="step.intentMap?.parentIntent">
                                     </mat-icon>

                                     <mat-icon fontIcon="repeat" color="accent" matTooltip="Step is on loop" *ngIf="step.intentMap?.isIntent">
                                    </mat-icon>
                                  </div>

                                </div>
                                <div  fxLayout="column" fxFlex="5">
                                  <div fxLayout="row" fxLayoutAlign="end center">
                                      <button *ngIf="editMode" style="cursor: pointer;"  (click)="$event.stopPropagation(); selectedConfigureFlow(step, i)"
                                          mat-icon-button color="primary"  matTooltip="Edit Step">
                                          <mat-icon >
                                            edit
                                          </mat-icon>
                                       </button>
                                  </div>
                              </div>
                                <div  fxLayout="column" fxFlex="5">
                                    <div fxLayout="row" fxLayoutAlign="end center">
                                        <button *ngIf="editMode" style="cursor: pointer;"  (click)="$event.stopPropagation(); stepDeleted($event, loadedFlow.steps)"
                                            mat-icon-button color="warn"  matTooltip="Delete Step">
                                            <mat-icon >
                                            delete
                                            </mat-icon>
                                         </button>
                                    </div>
                                </div>
                              </div>
                              <div>
                                <step-title-bar [index]="i" [steps]="loadedFlow.steps" [editMode]="editMode"
                                    (editStep)="selectedConfigureFlow(step, i)"
                                    (stepDeleted)="stepDeleted($event, loadedFlow.steps)">
                                </step-title-bar>
                              </div>
                              <div fxLayout="row" fxLayoutAlign="end end" *ngIf="step?.intentMap?.isIntent || step?.intentMap?.isIntentAvailable" style="padding: 5px;">
                              <button class="bottom_button"
                                    *ngIf="step?.intentMap?.isIntentAvailable && !step?.intentMap?.isIntent"
                                    (click)="intentAdded('add', step, i)" mat-stroked-button color="accent"
                                    matTooltip="Loop all following actions">
                                    Loop <mat-icon>
                                        all_inclusive
                                    </mat-icon>
                                </button>
                                <button class="bottom_button"
                                    *ngIf="step?.intentMap?.isIntent && !step.intentMap.isIntentRemoved"
                                    (click)="intentAdded('remove', step, i)" mat-stroked-button color="warn"
                                    matTooltip="Remove loop for all following actions">
                                    Remove <mat-icon>
                                        leak_remove
                                    </mat-icon>
                                </button>
                             </div>
                          </mat-card-content>
                        </mat-card>
                    </div>

                    <!-- BRANCHED FLOW STEP -->
                    <div *ngIf="step.control_step?.condition">
                        <div fxLayout="column" fxFlex="50" fxLayoutAlign="center center"
                            *ngIf="step.control_step.condition === 'TRUE'">
                            <mat-card>
                              <div>
                                <step-title-bar [index]="i" [steps]="loadedFlow.steps" [editMode]="editMode"
                                    (editStep)="selectedConfigureFlow(step, i)"
                                    (stepDeleted)="stepDeleted($event, loadedFlow.steps)">
                                </step-title-bar>
                              </div>
                            </mat-card>
                            <div style="height: 30px;border-left: 3px solid #939cce;"></div>

                            <button [matMenuTriggerFor]="menutrue"
                                class="2"
                                *ngIf="step.control_step?.condition === 'TRUE' && loadedFlow.steps.length - 1 === i && editMode"
                                style="cursor: pointer;margin: -10px 0px -10px 0px;" color="primary" mat-icon-button
                                matTooltip="Create Step">
                                <mat-icon>
                                    add_circle_outline
                                </mat-icon>
                            </button>
                            <mat-menu #menutrue="matMenu">
                                <button (click)="addNewBranchStep(i, 'action', step, 'TRUE')" mat-menu-item>
                                    <span>Action</span>
                                </button>
                                <button [matMenuTriggerFor]="menutruejoin" mat-menu-item>
                                    <span>Join branch</span>
                                </button>
                            </mat-menu>
                            <mat-menu #menutruejoin="matMenu">
                                <button (click)="addNewStep(i, 'action')" mat-menu-item>
                                    <span>Action</span>
                                </button>
                            </mat-menu>
                            <button class="3" *ngIf="step.control_step?.condition === 'TRUE' && loadedFlow.steps.length - 1 !== i && editMode"
                                style="cursor: pointer;margin: -10px 0px -10px 0px;"
                                (click)="addNewBranchStep(i, 'action', step, 'TRUE')" color="primary" mat-icon-button
                                matTooltip="Create Step">
                                <mat-icon>
                                    add_circle_outline
                                </mat-icon>
                            </button>
                        </div>


                        <div fxLayout="column" class="center-line2" fxFlex="50"
                            *ngIf="step.control_step.condition === 'TRUE'">
                        </div>
                        <div fxLayout="column" fxFlex="50" class="center-line1"
                            *ngIf="step.control_step.condition === 'FALSE'"> </div>
                        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="50"
                            *ngIf="step.control_step.condition === 'FALSE'">
                            <mat-card>
                              <div>
                                <step-title-bar [index]="i" [steps]="loadedFlow.steps" [editMode]="editMode"
                                    (editStep)="selectedConfigureFlow(step, i)"
                                    (stepDeleted)="stepDeleted($event, loadedFlow.steps)">
                                </step-title-bar>
                              </div>
                            </mat-card>
                            <div style="height: 30px;border-left: 3px solid #ce9393;"></div>
                            <button class="4" [matMenuTriggerFor]="menufalse"
                                *ngIf="step.control_step?.condition === 'FALSE' && loadedFlow.steps.length - 1 === i && editMode"
                                style="cursor: pointer;margin: -10px 0px -10px 0px;" color="warn" mat-icon-button
                                matTooltip="Create Step">
                                <mat-icon>
                                    add_circle_outline
                                </mat-icon>
                            </button>
                            <mat-menu #menufalse="matMenu">
                                <button (click)="addNewBranchStep(i, 'action', step, 'FALSE')" mat-menu-item>
                                    <span>Action</span>
                                </button>
                                <button [matMenuTriggerFor]="menufalsejoin" mat-menu-item>
                                    <span>Join branch</span>
                                </button>
                            </mat-menu>
                            <mat-menu #menufalsejoin="matMenu">
                                <button (click)="addNewStep(i, 'action')" mat-menu-item>
                                    <span>Action</span>
                                </button>
                            </mat-menu>
                            <button class="5" *ngIf="step.control_step?.condition === 'FALSE' && loadedFlow.steps.length - 1 !== i && editMode"
                                style="cursor: pointer;margin: -10px 0px -10px 0px;"
                                (click)="addNewBranchStep(i, 'action', step, 'FALSE')" color="warn" mat-icon-button
                                matTooltip="Create Step">
                                <mat-icon>
                                    add_circle_outline
                                </mat-icon>
                            </button>
                        </div>
                    </div>

                    <div
                        *ngIf="step.step_type !== 'control' && step.control_type !== 'branch' && !step.control_step?.condition">
                        <br />
                    </div>
                    <div class="line" (mouseenter)="addDummyStep(i)" style="margin-bottom: -15px;"
                        *ngIf="step.step_type !== 'control' && step.control_type !== 'branch' && !step.control_step?.condition">
                    </div>
                    <div
                        class="6"
                        fxLayout="row" fxLayoutAlign="center center"
                        *ngIf="step.step_type !== 'control' && step.control_type !== 'branch' && !step.control_step?.condition"
                        (mouseenter)="addDummyStep(i)"
                    >

                        <button style="cursor: pointer;" *ngIf="editMode" [matMenuTriggerFor]="menu2" color="primary" mat-icon-button
                            matTooltip="Create Step">
                            <mat-icon>
                                add_circle_outline
                            </mat-icon>
                        </button>
                    </div>
                    <mat-menu #menu2="matMenu">
                        <button (click)="addNewStep(i, 'action')" mat-menu-item>
                            <span>Action</span>
                        </button>
                        <button (click)="addNewStep(i, 'control')" mat-menu-item>
                            <span>Control</span>
                        </button>
                        <button (click)="addNewStep(i, 'processor')" mat-menu-item>
                          <span>Processor</span>
                        </button>
                    </mat-menu>

                    <!-- <mat-icon color="primary" fxLayout="row" fxLayoutAlign="center center" *ngIf="i + 1 < flowMap.steps.length" >
                    arrow_drop_down
                 </mat-icon> -->

                    <div *ngIf="step.step_type === 'control' && step.control_type === 'branch'">
                        <div fxLayout="column" fxFlex="50" fxLayoutAlign="center center">
                            <br />
                            <div class="left_line_branch"> </div>
                            <mat-icon style="cursor: pointer;" *ngIf="editMode" matTooltip="Create Action" color="primary"
                                (click)="addNewBranchStep(i, 'action', step, 'TRUE')">
                                add_circle_outline
                            </mat-icon>
                        </div>
                        <div fxLayout="column" fxFlex="50" fxLayoutAlign="center center">
                            <br />
                            <div class="right_line_branch"></div>
                            <mat-icon style="cursor: pointer;" *ngIf="editMode" color="warn" matTooltip="Create Action"
                                (click)="addNewBranchStep(i, 'action', step, 'FALSE')">
                                add_circle_outline
                            </mat-icon>
                        </div>
                    </div>
                    <div *ngIf="i + 1 < loadedFlow.steps.length && step.step_type !== 'control' && step.control_type !== 'branch' && !step.control_step?.condition"
                        class="line-bottom" style="margin-top: -15px;margin-bottom: -15px;"></div>

                    <br/>
                </div>
            </div>
        </div>
        <div fxLayout="column" fxFlex="20"></div>
    </div>
</ng-template>

</div>

</div>
