import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WidgetManager } from '../../models/WidgetManager';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AvailableOptions } from '../../models/widgetClasses/commonWidgetProps';
import { MetaService } from '../../services/meta-service';
import { WidgetService } from '../../services/widget-service.service';

@Component({
  selector: 'app-widget-display-config',
  templateUrl: './widget-display-config.component.html',
  styleUrls: ['./widget-display-config.component.css']
})
export class WidgetDisplayConfigComponent implements OnInit {

  @Input() widgetMeta;
  @Input() panelId;
  config: any = {}

  lastPlacedCursorPosition: number = -1
  pageReferenceMap: any = {
    list:[]
  }

  optionsMap: any = {}
  @Output() settingsChanged: EventEmitter<any> = new EventEmitter();
  constructor(
    private metaService: MetaService,
    private widgetService: WidgetService
  ) { }

  ngOnInit(): void {
    console.log("widgetMeta received in display config", this.widgetMeta)
    this.config = this.widgetMeta?.config

    this.ColorControl.valueChanges.pipe(debounceTime(3000), distinctUntilChanged()).subscribe(() => {
      console.log("color changed")
    })
    if(this.widgetMeta.config?.backgroundColor?.value) {
      this.ColorControl.patchValue(this.widgetMeta.config.backgroundColor.value)
    }else{
      this.ColorControl.patchValue('#FFFFFF')
    }
    this.initAvailableOptions()
    this.createPageRefMap()
  }
  ColorControl: UntypedFormControl = new UntypedFormControl('')

  selectedClass: any = ''
  fontClasses: any = [
    'Title',
    'Heading 1',
    'Heading 2',
    'Heading 3',
    'Heading 4',
    'Subtitle',
    'Normal',
    'Small',
    'Tiny',
  ]

  fontFamilies: any = [
    'Arial',
    'Verdana',
    'Helvetica',
    'Tahoma',
    'Trebuchet',
    'Times New Roman',
    'Georgia',
    'Courier New',
    'Brush Script MT',
  ]

  classSelectionHandler(event: any) {
    console.log("class selection changed", event);
    this.widgetMeta.textFormat.class.value = event.value;
    switch (event.value) {
      case 'Title':
        this.widgetMeta.textFormat.bold.value = true;
        this.widgetMeta.textFormat.fontSize.value = 37

        break;
      case 'Heading 1':
        this.widgetMeta.textFormat.bold.value = true;
        this.widgetMeta.textFormat.fontSize.value = 24

        break;
      case 'Heading 2':
        this.widgetMeta.textFormat.bold.value = true;
        this.widgetMeta.textFormat.fontSize.value = 21

        break;
      case 'Heading 3':
        this.widgetMeta.textFormat.bold.value = true;
        this.widgetMeta.textFormat.fontSize.value = 19

        break;
      case 'Heading 4':
        this.widgetMeta.textFormat.bold.value = true;
        this.widgetMeta.textFormat.fontSize.value = 18;
        this.widgetMeta.textFormat.italic.value = true;

        break;
      case 'Subtitle':
        this.widgetMeta.textFormat.fontSize.value = 24
        break;
      case 'Normal':
        this.widgetMeta.textFormat.fontSize.value = 14

        break;
      case 'Small':
        this.widgetMeta.textFormat.fontSize.value = 12
        break;
      case 'Tiny':
        this.widgetMeta.textFormat.fontSize.value = 8
        break;

      default:
        break;
    }
    this.settingsChanged.emit(this.widgetMeta);
  }

  styleChanged(event: any) {
    console.log("style changed", event.value)
    this.widgetMeta.textFormat.bold.value = event.value.includes('bold');
    this.widgetMeta.textFormat.italic.value = event.value.includes('italic');
    this.widgetMeta.textFormat.underline.value = event.value.includes('underline');
    this.settingsChanged.emit(this.widgetMeta);
  }

  fontSizeChanged(event: any) {
    console.log("Size Changed", event)
    let size = parseInt(event.value);
    if (size < 8 || size > 96)
      return
    this.widgetMeta.textFormat.fontSize.value = size;
    this.settingsChanged.emit(this.widgetMeta);
  }

  newAppFilterList(data){
    // this.data.widgetConfig
    this.settingsChanged.emit(this.widgetMeta);
  }

  fontStyleChanged(event: any) {
    console.log("font style changed", event)
    this.widgetMeta.textFormat.fontFamily.value = event.value
    this.settingsChanged.emit(this.widgetMeta);
  }

  colorChanged(event: any) {
    console.log("color changed", event.value)
    this.widgetMeta.config['backgroundColor'].value = event.value
    this.ColorControl.patchValue(event.value || '#FFFFFF')
    this.settingsChanged.emit(this.widgetMeta)
  }

  customPropertyChanged(event: any, propName: any) {
    let data = event.value
    console.log("input detected", event)
    console.log("propName", propName)
    console.log("value", this.widgetMeta.config[propName].value)

    // handle stringified json input
    // data = this.widgetMeta.config[propName].type == 'json' ? JSON.parse(data) : data

    this.widgetMeta.config[propName].value = data
    this.settingsChanged.emit(this.widgetMeta)
  }

  widgetNameChange(event: any) {
    this.widgetMeta.name = event.value
    this.settingsChanged.emit(this.widgetMeta)
  }

  customInputNumberPropertyChanged(event: any, propName: string) {
    let value = parseInt(event.value);
    if (typeof value != "number")
      return
    this.widgetMeta.config[propName].value = event.value
    this.settingsChanged.emit(this.widgetMeta)
  }
  onChangeIcon(event: any, propertyName: string) {
    this.widgetMeta.config[propertyName].iconName.value = event;
    this.settingsChanged.emit(this.widgetMeta)
  }

  checkBoxChanged(event: any, propName: string) {
    this.widgetMeta.config[propName].value = event.checked
    this.settingsChanged.emit(this.widgetMeta)
  }

  onHideTextChange(event: any, propName: string) {
    console.log(event);
    this.widgetMeta.config[propName].hideText.value = event.checked
    this.settingsChanged.emit(this.widgetMeta)
  }

  newOptions(widgetMeta){
    console.log("new options received", widgetMeta)
    this.widgetMeta.config.availableOptions = widgetMeta.config.availableOptions
    this.settingsChanged.emit(this.widgetMeta)
  }

  prettifyJSON(obj: any){
    return JSON.stringify(obj, null, 4)
  }

  expressionSelected(expressionData){
    console.log("expression configured", expressionData)
    console.log("widgetMeta", this.widgetMeta)
    // this.widgetMeta.setValue()
    let newWidget: any = WidgetManager.getWidget(this.widgetMeta.type)

    Object.keys(this.widgetMeta).forEach(k => newWidget[k] = this.widgetMeta[k])

    newWidget.setValue(expressionData.id)
    newWidget.config.expressionConfig = expressionData
    // console.log("new widget", newWidget)
    this.widgetMeta = newWidget
    console.log("widgetMeta updated", this.widgetMeta)
    this.settingsChanged.emit(this.widgetMeta)
  }

  defaultValueTypeChanged(event){
    console.log("defaultvalueTypeChanged", event)
    this.widgetMeta.config.defaultValue.dynamic = event.value
  }

  initAvailableOptions(){
    console.log("widget meta", this.widgetMeta)
    this.widgetMeta.config.props.forEach((property: any) => {
      console.log("dealing prop", property)
      if(this.widgetMeta.config[property].type == 'select' && AvailableOptions[property] && AvailableOptions[property].widgetsSupported.includes(this.widgetMeta.type)){
        this.optionsMap[property] = AvailableOptions[property]?.availableOptions || []
      }
    })
    console.log("optionsMap initialized", this.optionsMap)
  }


  createPageRefMap(){
    let pageMeta = this.metaService.pageMeta.value
    pageMeta.panels?.forEach(panel => {
      if(panel.type !== 'listpanel' && panel.type !== 'searchpanel') {
        // this.allPanels.push({ id: panel.id, name: panel.name, type: panel.type, layoutMap: panel.layoutMap })
        
        let widgetOptions: any[] = []
        let widgetMetas = this.widgetService.getWidgetsFromPanel(panel)
        widgetMetas.forEach(wid => {
          widgetOptions.push({
            name: wid.name,
            __id: `${panel.id}.${wid.id}`,
            value: '${' + `${panel.id}.${wid.id}` + '}',
            dataType: "string"
          })
        })

        this.pageReferenceMap.list.push(panel.id)
        this.pageReferenceMap[panel.id] = {
          id: panel.id,
          displayName: panel.name,
          fields: widgetOptions,
          options: {}
        }
      }
    });
    console.log("page refs set", this.pageReferenceMap)
  }

  templateValueSelected(event, prop, value){
    console.log("event", event)
    let position = this.lastPlacedCursorPosition == -1 ? value.length : this.lastPlacedCursorPosition
    value = value.substring(0, this.lastPlacedCursorPosition) + " " + event.value + " " + value.substring(this.lastPlacedCursorPosition)
    this.widgetMeta.config[prop].value = value
    console.log("widget meta changed", this.widgetMeta)
    this.settingsChanged.emit(this.widgetMeta)
  }

  cursorPlaced(event){
    console.log("cursor placed", event)
    this.lastPlacedCursorPosition = event.target?.selectionStart
  }
}
