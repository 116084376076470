import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import * as Moment from 'moment-timezone';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { FlowContentService } from 'src/app/flow/flow-content.service';

@Component({
  selector: 'step-action',
  templateUrl: './step-action.component.html',
  styleUrls: ['./step-action.component.scss'],
})
export class StepActionComponent implements OnInit {
  @Input() 'step': any;
  @Input() 'sourceFields': any;
  @Input() 'editMode': boolean = true;
  @Output() 'onConfigure' = new EventEmitter<any>();
  @Output() 'stepSourceFields' = new EventEmitter<any>();
  @Output() 'actionChanged' = new EventEmitter<any>();
  @Output() 'userChanges' = new EventEmitter<any>();

  systemFields: any = this.flowContentService.systemFields;

  stepOptions: any = {
    connectionOptions: [],
    actionFields: [],
    box: {},
    action: {},
  };

  connectionSelectionConfig: any = {}
  optionalInputs = {
    options: {
      object: this.stepOptions.action.__id ? this.stepOptions.action.__id.split('/')[0] : ''
    }
  }

  constructor(private ngZone: NgZone, private flowContentService: FlowContentService) {}

  ngOnInit(): void {
    console.log("===> STEP", this.step)
    console.log("sourceFields--->",this.step.step_code, "--->", this.sourceFields)
  };

  async stepActionSelected(fields: any) {
    console.log("stepAction Selected", fields)
    this.stepOptions.actionFields = fields;
    console.log("step options", JSON.parse(JSON.stringify(this.stepOptions)))
    this.onConfigure.emit(this.step);
    this.emitStepSourceFields()
    this.userMadeChanges();
  }

  appSelected(e){
    console.log("appselected", e);
    this.step.box_id = e.__id;
    this.step.box_logo_url = e.logo;
    this.step.box_name = e.name;
    this.stepOptions.box = e;

    this.connectionSelectionConfig = { box_id: this.step.box_id }
    this.userMadeChanges();
    // this.actionConfig.actionMap.boxId = e.__id;
  }

  mappingAppInfo(box:any){
    return {
      app: box?.name || box.__id,
      logo : {url: box?.logo}
    }
  }

  connectionSelected(event){
    console.log("connection received")
    this.step.connection = event._id;
    this.stepOptions.connection = event
    console.log("stepOptions now", this.stepOptions)
    this.userMadeChanges();
  }

  baseSelected(base){
    this.step['baseMap'] = {
      box_id: base.storage_box,
      base_id: base._id
    };
    this.step.boxConfigToken = base.storage_token;
    this.userMadeChanges();
  }

  emitStepSourceFields(){

    let stepSourceFields = [];
    let actionFields = JSON.parse(JSON.stringify(this.stepOptions?.actionFields));
    actionFields.forEach(element => {
      element.__id = this.step.step_code + "." + element.__id || element.name;
      stepSourceFields.push(element);
    });

    if(this.stepOptions?.action?.output?.list){
      let output = JSON.parse(JSON.stringify(this.stepOptions?.action?.output));
      output?.list?.forEach(element => {
        let obj = output[element];
        obj.__id = this.step.step_code + "." + obj.__id || obj.name;
        stepSourceFields.push(obj);
      });
    }
    console.log("[emitStepSourceFields]", stepSourceFields)
    this.stepSourceFields.emit(stepSourceFields);
  }

  onmappingChange(e) {
    console.log("[onMappingChange]")
    this.step.mapping = JSON.parse(JSON.stringify(e));
    this.userMadeChanges();
    this.onConfigure.emit(this.step);
  }
  selectedAction(action){
    console.log("[selectedAction] action --->", action)
    this.userMadeChanges();
    this.actionChanged.emit(action);
  }

  userMadeChanges() {

    this.userChanges.emit(true);
  }

  attributeOptionsInput(event, step){
    if(!step.actionMap) step.actionMap = {};
    step.actionMap.attributeInputOptions = event;
    console.log("attributeOptionsInput step.actionMap", step.actionMap)
  }

  inputRecevied(e){
    console.log("[inputReceived]", e)
    // if(e.sort){ e.sortConfig = e.sort; delete e.filter }
    // if(e.sort){ e.filterConfig = e.filter; delete e.sort }
    // if(e.limit){ delete e.limit}
    let temp = {
      query: e.query,
      filterConfig: e.filter,
      groupConfig: e.group,
      __limit: e.limit || e.query?.page?.split('|')[1] || 100
    }
    Object.assign(temp, e);
    console.log("temp", temp)
    this.step.inputParams = JSON.parse(JSON.stringify(temp));
    console.log("inputRecevied and params is", this.step.inputParams);
    this.onConfigure.emit(this.step);
    this.userMadeChanges();
  }

  // onIntentChange(type){
  //   this.intentChanged.emit(type);
  // }
}
