import { Component, Input, OnInit, OnChanges, Output, ViewChild, EventEmitter, SimpleChanges, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { environment } from 'src/environments/environment';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Inject } from '@angular/core';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';

@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.css']
})
export class EmbedComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any;
  styles: any;
  safeUrl: any;
  height: number = 0;
  width: number = 0;

  oldInputURL: string = ''

  // textEditMode: boolean = false;

  @Output() applyWidgetHeight = new EventEmitter<any>();

  @ViewChild('cover') cover: ElementRef
  @ViewChild('iframe') iframe: ElementRef
  private destroy:any = new Subject();
  isBrowser: any;

  constructor(
    private _sanitizer: DomSanitizer, 
    public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      super(metaService, pageService, resourcePermissionService)
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;

  }

  ngOnInit(): void {
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngAfterViewInit(): void {
    console.log("cover", this.cover)
    console.log("iframe", this.iframe)
    this.calculateHeight()
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes", changes)

    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }
  }

  ngDoCheck(): void {
    if (this.oldInputURL == this.widgetMeta.config.url.value) return;

    this.oldInputURL = this.widgetMeta.config.url.value;

    try {
      let url = new URL(this.widgetMeta.config.url.value);
      this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(url.toString());
    } catch (e) {
      console.log("invalid url");
      this.safeUrl = null; // Clear safeUrl on invalid URLs
    }
  }



  //------------------METHODS-----------------------

  calculateHeight() {
    const gridX = this.widgetMeta.gridX;
    const gridY = this.widgetMeta.gridY;
    const coverWidth = this.cover.nativeElement.clientWidth;
    const newHeight = Math.floor((coverWidth / gridX) * gridY);
    this.height = newHeight;
    this.applyWidgetHeight.emit(this.height)
  }

  calculateWidth() {
    const gridX = this.widgetMeta.gridX;
    const gridY = this.widgetMeta.gridY;
    const coverWidth = this.cover.nativeElement.clientWidth;
    const newWidth = Math.floor((coverWidth / gridY) * gridX);
    this.width = newWidth;
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "edit",
        "url"
      ],
      url: {
        type: this.widgetMeta?.config.url.type
      }
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)
    switch (event.actionType) {
      case "delete":
        this.onDelete()
        break;
      case "updateStyles":
        console.log("action type: update styles");
        if (event?.data) {
          this.widgetMeta = event.data;
        }
        super.generateStyles()
        break;
      case "customPropertyUpdate":
        console.log("RETURNED FROM MENU", event)
        if (event.propertyName == 'url') { 
          this.widgetMeta.config.url.value = event.data.config.url.value
          this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(event.data) 
        }
        this.newWidgetMeta.emit(this.widgetMeta)
        break;
      default:
        break;
    }
  }
  fitIframeContent(event: Event, iframe: HTMLIFrameElement) {
    if (!iframe.contentDocument) return;

    const contentDocument = iframe.contentDocument;
    const contentHeight = contentDocument.body.scrollHeight || 0;
    const contentWidth = contentDocument.body.scrollWidth || 0;
    iframe.style.height = `${contentHeight}px`;
    iframe.style.width = `${contentWidth}px`;
  }


  calculateIframeHeight(): string {
    // Calculate the desired height for the iframe in !builderMode
    const windowHeight = window.innerHeight;
    const headerHeight = 64; // Adjust this value if you have a header
    const footerHeight = 64; // Adjust this value if you have a footer
    const desiredHeight = windowHeight - headerHeight - footerHeight;
    return `${desiredHeight}px`;
  }

  calculateIframeWidth(): string {
    // Calculate the desired width for the iframe in !builderMode
    const desiredWidth = '500%'; // Adjust this value based on your requirements
    return desiredWidth;
  }

  onClick(event: any) {
    console.log("embedded iframe clicked")
    this.onOuterClick()
  }
  
  onOuterClick(event?) {
    console.log("embed outer clicked in widget")
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
  }
  onResizeStart(event: any) {
    event.stopPropagation();
  }

  onResizeEnd(event: any) {
    const newHeight = event.target.clientHeight;
    const newWidth = event.target.clientWidth;
    this.height = newHeight;
    this.width = newWidth;
  }

}
