<!-- <mat-tab-group>
  <mat-tab>
  </mat-tab>
</mat-tab-group> -->

<div class="cover" fxFlexFill fxLayout="row wrap" fxLayoutAlign="flex-start center">

  <!-- ----------------------------- WIDGET NAME ----------------------------- -->
  <mat-form-field fxFlex="95%">
    <mat-label>Widget name</mat-label>
    <input matInput type="text" name='widgetName' [value]="widgetMeta.name"
      (input)="widgetNameChange($event.target)" placeholder="Give a name for the widget">
  </mat-form-field>

  <!-- ----------------------------- WIDGET PROPERTY TYPE SWITCH ----------------------------- -->
  <div *ngFor="let prop of widgetMeta.config.props" fxFlex="100%">
    <div *ngIf="widgetMeta.config?.[prop] && widgetMeta.config[prop].type">
    <ng-container [ngSwitch]="widgetMeta.config[prop].type">
      <ng-container *ngSwitchCase="'text'">
        <ng-container *ngTemplateOutlet="input"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'datetime'">
        <ng-container *ngTemplateOutlet="input"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'longtext'">
        <ng-container *ngTemplateOutlet="longtext"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'json'">
        <ng-container *ngTemplateOutlet="json"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'password'">
        <ng-container *ngTemplateOutlet="input"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'number'">
        <ng-container *ngTemplateOutlet="inputnumber"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'boolean'">
        <ng-container *ngTemplateOutlet="boolean"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'radio'">
        <ng-container *ngTemplateOutlet="radio"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'app-alignment-settings'">
        <ng-container *ngTemplateOutlet="appAlignmentSettings"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'color-picker'">
        <ng-container *ngTemplateOutlet="colorPicker"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'app-filter'">
        <box-filter-config
        [widgetMeta]="widgetMeta"
        (filterChanged)="newAppFilterList($event)"> </box-filter-config>
      </ng-container>
      <!-- <ng-container *ngSwitchCase="'selection-widgets-options-config'">
        <ng-container *ngTemplateOutlet="selectionWidgetsOptionsConfig"></ng-container>
      </ng-container> -->

      <!---------------------------- BUTTON TYPE SELECTION ---------------------------->
      <ng-container *ngSwitchCase="'select'">
        <mat-form-field class="dropdown" flex="95%"
          (click)="$event.stopPropagation();$event.preventDefault();">
          <mat-label>{{widgetMeta.config[prop].displayName}}</mat-label>
          <mat-select [value]="widgetMeta.config[prop].value"
            (selectionChange)="customPropertyChanged($event,prop)">
            <mat-option [value]="type" *ngFor="let type of optionsMap[prop]">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <!---------------------------- ICON PICKER ---------------------------->
      <ng-container *ngSwitchCase="'iconPicker'">
        <div class="icon-picker-div">
          <div>
            <mat-checkbox class="checkbox-margin" [labelPosition]="'after'" color="primary"
              (change)="checkBoxChanged($event,prop)" [checked]="widgetMeta.config[prop].value">
              {{widgetMeta.config[prop].displayName}}</mat-checkbox>
          </div>
          <div class="section-layout">
            <app-googleicon-picker *ngIf="widgetMeta.config[prop].value"
              [label]="widgetMeta.config[prop].iconName.displayName"
              [value]="widgetMeta.config[prop].iconName.value" (iconSelectionChanged)="onChangeIcon($event,prop)"
              appearance="standard"></app-googleicon-picker>
            <section *ngIf="widgetMeta.config[prop].value">
              <mat-checkbox class="checkbox-margin" [labelPosition]="'after'" color="primary"
                (change)="onHideTextChange($event,prop)" [checked]="widgetMeta.config[prop].hideText.value">
                {{widgetMeta.config[prop].hideText.displayName}}</mat-checkbox>
            </section>
          </div>
        </div>
      </ng-container>


      <!--------------------------- DEFAULT VALUE --------------------------->
      <ng-container *ngSwitchCase="'defaultValue'">
        <mat-slide-toggle
          [checked]="widgetMeta.config.defaultValue.enabled"
          (change)="widgetMeta.config.defaultValue.enabled = $event.checked"
          color="primary"
        >Default Value</mat-slide-toggle>

        <div *ngIf="widgetMeta.config.defaultValue.enabled">
          <div style="height: 1rem;"></div>
          <!-- <div>{{ widgetMeta.config.defaultValue.dynamic }}</div> -->
          <mat-radio-group color="primary" (change)="defaultValueTypeChanged($event)">
            <mat-radio-button [value]="false" [checked]="widgetMeta.config.defaultValue.dynamic === false" style="margin-right: 1rem">Static Value</mat-radio-button>
            <mat-radio-button [value]="true" [checked]="widgetMeta.config.defaultValue.dynamic === true">Dynamic Value</mat-radio-button>
          </mat-radio-group>

          <ng-container *ngIf="widgetMeta.config.defaultValue.dynamic; else elseTemplate">
            <div style="height: .5rem;"></div>
            <app-expressions-selection
              [widgetExpressionConfig]="
                widgetMeta.config.defaultValue.enabled && widgetMeta.config.defaultValue.dynamic ?
                widgetMeta.config.expressionConfig : null"
              [widgetType]="widgetMeta.type"
              (expressionSelected)="expressionSelected($event)"
            >
            </app-expressions-selection>
          </ng-container>
          <ng-template #elseTemplate>
            <div style="height: .5rem;"></div>
            <mat-form-field style="width: 50%;">
              <mat-label>Static Value</mat-label>
              <input matInput [(ngModel)]="widgetMeta.config.defaultValue.value">
            </mat-form-field>
          </ng-template>
        </div>

      </ng-container>

      <!---------------------------- OUTPUT DATE/TIME FORMAT SELECTION ---------------------------->

      <ng-container *ngSwitchCase="'date-time-format-input'">
        <date-time-format-config
          [widgetMeta]="widgetMeta"
          (dateTimeFormatChanged)="widgetMeta = $event"
        ></date-time-format-config>
      </ng-container>

      <ng-container *ngSwitchDefault></ng-container>


      <!----------------------------------------- TYPE TEMPLATES ---------------------------------------->
      <!-- ------------------------------------------------------------------------------------------- -->


      <!---------------------------- INPUT ---------------------------->
      <ng-template #input>
        <mat-form-field fxFlex="95%">
          <mat-label>{{widgetMeta.config[prop].displayName}}</mat-label>
          <input matInput [type]="widgetMeta.config[prop].type" name="value" [value]="widgetMeta.config[prop].value"
            maxlength="255" (input)="customPropertyChanged($event.target,prop)">
        </mat-form-field>
      </ng-template>

      <!---------------------------- LONG TEXT ---------------------------->
      <ng-template #longtext>
        <mat-form-field fxFlex="95%">
          <mat-label>{{widgetMeta.config[prop].displayName}}</mat-label>
          <textarea matInput name="value" [value]="widgetMeta.config[prop].value"
            (input)="customPropertyChanged($event.target, prop)" (click)="cursorPlaced($event)">
          </textarea>
        </mat-form-field>
        <app-field-picker class="fieldPicker"
          [inputFieldMap]="pageReferenceMap"
          (selectedFields)="templateValueSelected($event, prop, widgetMeta.config[prop].value)"
        ></app-field-picker>
      </ng-template>

      <!---------------------------- JSON ---------------------------->
      <ng-template #json>
        <mat-form-field fxFlex="95%">
          <mat-label>{{widgetMeta.config[prop].displayName}}</mat-label>
          <textarea disabled matInput name="value" [value]="prettifyJSON(widgetMeta.config[prop].value)"
            (input)="customPropertyChanged($event.target, prop)">
          </textarea>
        </mat-form-field>
      </ng-template>

      <!---------------------------- NUMBER ---------------------------->
      <ng-template #inputnumber>
        <mat-form-field fxFlex="95%">
          <!-- <mat-icon matPrefix color="primary">
            {{ descriptor.icon }}
          </mat-icon> -->
          <mat-label>
            {{widgetMeta.config[prop].displayName}}
          </mat-label>
          <input matInput type="number" name="value" [value]="widgetMeta.config[prop].value"
           (input)="customInputNumberPropertyChanged($event.target,prop)">
        </mat-form-field>
      </ng-template>

      <!---------------------------- BOOLEAN ---------------------------->
      <ng-template #boolean>
        <mat-checkbox [(ngModel)]="widgetMeta.config[prop].value" fxFlex="70%" color="primary">
          {{widgetMeta.config[prop].displayName}}
        </mat-checkbox>
      </ng-template>

      <ng-template #radio>
        <mat-checkbox [(ngModel)]="widgetMeta.config[prop].value" fxFlex="95%" color="primary">
          {{widgetMeta.config[prop].displayName}}
        </mat-checkbox>
      </ng-template>

      <ng-template #colorPicker>
        <div fxLayoutAlign="start center" style="margin: .5rem 0">
          <div style="font-size: 1.1rem; margin-right: 1rem;">Background color</div>
          <div style="width: 3rem; margin-right: 1rem;">
            <input #colorInput class="colorInput" matInput type="color" name="color" [formControl]="ColorControl"
              (input)="colorChanged($event.target)">
          </div>
          <button mat-stroked-button (click)="colorChanged({value: ''})">
            <mat-icon>replay</mat-icon>
            Reset</button>
        </div>
      </ng-template>


      <!---------------------------- ALIGNMENT SETTINGS ---------------------------->
      <ng-template #appAlignmentSettings>
        <div fxFlex="95" class="topBottomMargin">
          <app-alignment-settings [disableSpaceBetween]="true" [alignment]="widgetMeta.config[prop].value"
            (newAlignment)="widgetMeta.config[prop].value = $event">
          </app-alignment-settings>
        </div>
      </ng-template>

      <!---------------------------- selection-widgets-options-config ---------------------------->
      <ng-template #selectionWidgetsOptionsConfig>
        <div fxFlex="100" class="topBottomMargin">
          <selection-widgets-options-config [widgetMeta]="widgetMeta" (newMeta)="newOptions($event)"></selection-widgets-options-config>
        </div>
      </ng-template>


    </ng-container>
    </div>
  </div>


  <!-- FONT FAMILY -->
  <div *ngIf="widgetMeta.textFormat && widgetMeta.textFormat.fontFamily" class="font-layout" fxFlex="95" fxLayout>
    <mat-form-field class="dropdown"
      (click)="$event.stopPropagation();$event.preventDefault();">
      <mat-label>
        Font
      </mat-label>
      <mat-select [value]="widgetMeta.textFormat.fontFamily.value" (selectionChange)="fontStyleChanged($event)">
        <mat-option [value]="fontFamily" *ngFor="let fontFamily of fontFamilies">{{ fontFamily }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- FONT CLASS -->
  <div *ngIf="widgetMeta.textFormat && widgetMeta.textFormat.class" class="font-layout" fxFlex="95" fxLayout>
    <mat-form-field fxFlexFill>
      <mat-label>Font Class</mat-label>
      <mat-select [value]="widgetMeta.textFormat.class.value" (selectionChange)="classSelectionHandler($event)">
        <mat-option></mat-option>
        <mat-option [value]="option" *ngFor="let option of fontClasses">{{ option }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- FONT SIZE -->
  <div *ngIf="widgetMeta.textFormat && widgetMeta.textFormat.fontSize" class="font-layout" fxFlex="95" fxLayout>
    <mat-form-field fxFlexFill>
      <mat-label>Font Size</mat-label>
      <input matInput type="number" min="8" max="48" name="fontSize" [value]="widgetMeta.textFormat.fontSize.value"
        (change)="fontSizeChanged($event.target)">
    </mat-form-field>
  </div>

  <!-- BOLD | ITALIC | UNDERLINE -->
  <div
    *ngIf="widgetMeta.textFormat && widgetMeta.textFormat.bold && widgetMeta.textFormat.italic && widgetMeta.textFormat.underline"
    class="bui-layout" fxFlex="95" fxLayout fxLayoutAlign="flex-start center" class="topBottomMargin">
    <mat-button-toggle-group (change)="styleChanged($event)" multiple name="fontStyle" aria-label="Font Style">
      <mat-button-toggle [checked]="widgetMeta.textFormat.bold.value" value="bold" matTooltip="Bold">
        <mat-icon>format_bold</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle [checked]="widgetMeta.textFormat.italic.value" value="italic" matTooltip="Italic">
        <mat-icon>format_italic</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle [checked]="widgetMeta.textFormat.underline.value" value="underline"
        matTooltip="Underlined">
        <mat-icon>format_underlined</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

</div>