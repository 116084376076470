import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { StarchService } from 'src/app/shared/services/starch.service';
import { BoxService } from 'src/app/bloom/services/box-service.service';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { Panel } from 'src/app/bloom/models/panelClasses/basePanel';


@Component({
  selector: 'starch-base-panel-popup',
  templateUrl: './starch-base-panel-popup.component.html',
  styleUrls: ['./starch-base-panel-popup.component.scss'],
  providers: []
})
export class StarchBasePanelPopupComponent implements OnInit {

  panelMap:any = {
    'searchPanel':  {
      checked: false,
      name: "Search Panel"
     },
    'listPanel': {
      checked: true,
      name: "List Panel"
    },
    'formPanel': {
      checked: false,
      name: "Form Panel"
    },
    'detailsPanel':{
      checked: false,
      name: "Details Panel"
    }
  }
  origin: any = "base"; 
  data: any
  baseMap: any = {}
  conKey: any;boxId: any;boxObjectId: any;conType: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public dataInput: any,
    public dialog: MatDialogRef<StarchBasePanelPopupComponent>, 
    private spinnerService: SpinnerService,
    public starchService: StarchService,
    private boxService: BoxService,
    public WidgetUtilityService: WidgetUtilityService,
    public automationService: AutomationService,
    private metaService: MetaService,
    private pageService: PageService,
  ) { }



  async ngOnInit() {
    if(this.dataInput?.type)this.origin = this.dataInput?.type;
    this.data = this.dataInput?.data;
    this.baseMap = this.data?.baseMap

    this.conKey = this.baseMap?.storage_token;
    this.boxId = this.baseMap?.storage_box;
    this.boxObjectId = this.data.__id;
    this.conType = 'token';
    if(this.origin != "base"){
      this.baseMap = this.dataInput?.data?.connection;
      this.data = this.dataInput?.data?.object;
      this.conKey = this.baseMap?._id;
      this.boxId = this.baseMap?.box_id;
      this.boxObjectId = this.data.__id;
      this.conType = '';
    }
    
    console.log("data", this.data);
    console.log("baseMap", this.baseMap)
    console.log("this.conKey, this.boxId, this.boxObjectId, this.conType", this.conKey, this.boxId, this.boxObjectId, this.conType)
  }


  async getAttributes(){
    
    let payloadOptions = {
      relationObject: "starch_relationship"
    }
    let boxAttributeRes:any = await this.boxService.getAttributes(this.conKey, this.boxId, this.boxObjectId, {}, null, this.conType, payloadOptions)
    return boxAttributeRes;
  }

  filterAttributesForForm(boxAttributeRes){
    let attributes = [];
    boxAttributeRes.forEach(element => {
      if(!element.parent && !element.hasOwnProperty('writable') && ['string', 'number', 'date', 'datetime'].includes(element.dataType)){
        element['enabled'] = true;
        element.editable = true;
        element.widgetType = "input";
        if(element.primary){
          element.editable = false;
        } else if(element.relation){
          element.widgetType = "autocomplete";
        }
        attributes.push(element)
      }
    });
    return attributes;
  }

  filterAttributesForDetail(boxAttributeRes){
    let attributes = [];
    boxAttributeRes.forEach(element => {
      if(!element.hasOwnProperty('readable') && ['string', 'number', 'date', 'datetime'].includes(element.dataType)){
        element['enabled'] = true;
        element.editable = true;
        element.widgetType = "input";
        if(element.primary){
          element.editable = false;
        } else if(element.relation){
          element.widgetType = "autocomplete";
        }
        attributes.push(element)
      }
    });
    return attributes;
  }

  async createPanels(){
    this.spinnerService.show();
    let boxAttributeRes:any = await this.getAttributes()
    let boxAttributes = boxAttributeRes?.result;
    let primaryAttribute = boxAttributes.find(attr => attr.primary);

    console.log("primaryAttribute", primaryAttribute)

    let result:any = {panelMap: this.panelMap}
    var listAttributes = []
    // intellisense attributes
    let intelliSensed = this.WidgetUtilityService.intelliSenseAttribute(boxAttributes, 5);
      intelliSensed.forEach(attr => {
      attr['sortEnabled'] = false
      attr['show_hide'] = false
      attr['fieldType'] = 'attribute'
      attr['widgetType'] = 'label'
    });
    listAttributes = intelliSensed;

    if(this.panelMap.detailsPanel.checked){
      let panelConfig = this.detailsPanelConfig();
      let panelAttributes = JSON.parse(JSON.stringify(listAttributes))
      panelAttributes.forEach(ele => {
        ele['enabled'] = true;
        ele['widgetType'] = 'label';
        ele['editable'] = true;
      })
      panelAttributes =  this.addPrimaryAttribute(panelAttributes, primaryAttribute, "detail")
      console.log("panelAttributes", panelAttributes)
      panelConfig.detailsAttributes = panelAttributes;
      panelConfig.layoutMap = await this.automationService.generateDetailsWidgets(panelConfig);
      panelConfig.primaryAttribute = primaryAttribute;
      result.detailsPanelConfig = panelConfig;
      result.detailsPanelConfig.name = 'detailsPanel_1';
      result.detailsPanelConfig.id = new Date().setSeconds(new Date().getSeconds() + 20).valueOf();
    }

    if(this.panelMap.searchPanel.checked){
      let panelConfig = this.searchPanelConfig();
      let panelAttributes = JSON.parse(JSON.stringify(listAttributes))
      panelAttributes.forEach(ele => {
        ele['widgetType'] = 'label';
      })
      panelConfig.searchAttributes = this.WidgetUtilityService.intelliSenseAttribute(panelAttributes, 4);
      result.searchPanelConfig = panelConfig;
      
    }


    if(this.panelMap.formPanel.checked){
      let panelConfig:any = this.formPanelPanelConfig();
      let panelAttributes = this.filterAttributesForForm(JSON.parse(JSON.stringify(boxAttributes)))//JSON.parse(JSON.stringify(listAttributes))
      // panelAttributes.forEach(ele => {
      //   ele['enabled'] = true;
      //   ele['widgetType'] = 'input';
      //   ele['editable'] = true;
      // })
      panelAttributes =  this.addPrimaryAttribute(panelAttributes, primaryAttribute)

      let submitButton:any = this.formPanelButton();

      console.log("submitButton", submitButton)
      submitButton.id = Date.now();
      submitButton['actionConfig']['actions'][0]['actionMap']['mapping'] = [];

      panelConfig.submitButtonMeta = submitButton;
      panelConfig.name = 'formPanel_1';
      panelConfig.id = new Date().setSeconds(new Date().getSeconds() + 50).valueOf();
      panelConfig.formAttributes = panelAttributes;

      
      panelConfig = this.automationService.generateFormWidgets(panelAttributes, panelConfig, submitButton, submitButton['actionConfig']['actions'][0]['actionMap'].action)
      console.log("panelConfig", panelConfig)
      result.formPanelConfig = panelConfig;
    }

    if(this.panelMap.listPanel.checked){
      let panelAttributes = JSON.parse(JSON.stringify(listAttributes))
      let panelConfig = this.listPanelConfig(primaryAttribute);

      let deleteAttribute:any = this.getListIconAttri('delete', primaryAttribute)
      panelAttributes.push(deleteAttribute);

      var formCode;
      if(this.panelMap.formPanel.checked){
        let editAttribute:any = this.getListIconAttri('edit', primaryAttribute)
        let backButtonPanel = this.detailPanelBackButtonPanel();
        formCode = await this.createPageAndPanel("form", [backButtonPanel, result.formPanelConfig]);
        if(editAttribute.navigationSettings) editAttribute.navigationSettings['pageCode'] = formCode;
        panelAttributes.push(editAttribute);
        result.formPanelCreatePanel = this.formPanelCreatePanel(formCode);
        result.formPanelCreatePanel2 = this.formPanelCreatePanel(formCode, "flex-start")
      }

      if(this.panelMap.detailsPanel.checked){
        let viewAttribute:any = this.getListIconAttri('view', primaryAttribute);
        // result.detailsPanelConfig.name = 'detailsPanel_1';
        // result.detailsPanelConfig.id = new Date().setSeconds(new Date().getSeconds() + 20).valueOf();
        let backButtonPanel = this.detailPanelBackButtonPanel();
        let panels = [backButtonPanel, result.detailsPanelConfig]
        if(this.panelMap.formPanel.checked){
          console.log("result.detailsPanelConfig", result.detailsPanelConfig)
          let detailsWidget = this.automationService.getWidgetsFromPanel(result.detailsPanelConfig);
          console.log("detailsWidget", detailsWidget)
          let detailPanelPrimaryWidget = this.getPrimaryWidget(detailsWidget, primaryAttribute);
          console.log("detailPanelPrimaryWidget", detailPanelPrimaryWidget)
          let editButtonDetailPanel = this.detailPanelEditButtonPanel(detailPanelPrimaryWidget, primaryAttribute, result.detailsPanelConfig.id, formCode);
          console.log("editButtonDetailPanel", editButtonDetailPanel)
          panels.push(editButtonDetailPanel);
        }
        
        let detailsCode = await this.createPageAndPanel("details", panels);
        if(viewAttribute?.navigationSettings) viewAttribute.navigationSettings['pageCode'] = detailsCode;
        panelAttributes.push(viewAttribute);
      }
      
      panelConfig.listAttributes = panelAttributes;
      panelConfig.listWidgetSet = this.generateWidgetSet(panelAttributes);
      result.listPanelConfig = panelConfig;
    }

    this.spinnerService.hide();
    this.dialog.close(result);
  }

  delete(){
    this.dialog.close(true);
  }

  dontDelete(){
    this.dialog.close(false);
  }

  detailPanelEditButtonPanel(widget, primaryAttribute, formPanelId, pageCode){
    let buttonWidget = WidgetManager.getWidget('button');
    let actionConfig = {
      "actions": [{
          "event": "click",
          "action": "navigation",
          "actionMap": {
            "mapping": [],
            "effectStyles": [],
            "parameters": [{
              keyField: {__id: primaryAttribute.__id},
              sourceField: {
                __id: `${formPanelId}.${widget.id}.value`,
                name: `Starch - ${this.boxObjectId} - Details ${widget.name}`,
                dataType: `string`
              }
            }],
            "pageType": "bloom_page",
            "page": pageCode
          }}]
    }
    buttonWidget.actionConfig = actionConfig;
    buttonWidget.config.buttonText.value = `Edit ${this.boxObjectId}`;
    buttonWidget.securityConfig = {"securities": []}

    let result = {
      type: "regular",
      layout: "flex-start",
      widgets: [buttonWidget],
      selectedNow: false,
      name: `edit ${pageCode}`,
      id: new Date().setSeconds(new Date().getSeconds() + 60).valueOf()
    }

    return result
  }

  addPrimaryAttribute(panelAttributes, attribute, type?){
    let primaryAttribute = JSON.parse(JSON.stringify(attribute));
    let widgetType = (type && type == 'detail') ? 'label' : 'input';
    let isPrimaryExists = false;
    for (let index = 0; panelAttributes < panelAttributes.length; index++) {
      const element = panelAttributes[index];
      if(element.__id == primaryAttribute.__id){
        isPrimaryExists = true;
        break;
      }
      
    }
    if(!isPrimaryExists) {
      primaryAttribute.enabled =  true;
      primaryAttribute.widgetType = widgetType;
      primaryAttribute.editable = true;
    } 
    panelAttributes.push(primaryAttribute)
    return panelAttributes;
  }

  getPrimaryWidget(widgets, primaryAttribute){
    console.log("widgets, primaryAttribute", widgets, primaryAttribute)
    let result
    for (let index = 0; index < widgets.length; index++) {
      const element = widgets[index];
      let id = element?.id?.split("-")?.[1]  || null;
      console.log("id", id)
      if(id == primaryAttribute.__id){
        result = element;
        break;
      }
    }
    return result;
  }

  async createPageAndPanel(type, panels){
    this.spinnerService.show();
    let pagesLength = this.metaService.loadedPageStructure?.pages?.length || 0;
    let code = `${this.data.__id}_${type}_${pagesLength}`;
    let name = `${this.data.__id} ${type}`;
    let page = this.pageService.createPageMeta(code, name, panels);
    console.log("type page", type, page)
    let newPageMeta = await this.metaService.create(page);
    // this.metaService.loadedPageStructure
    let newPageStructure = JSON.parse(JSON.stringify(this.metaService.loadedPageStructure))
    newPageStructure.pages.push(code)
    newPageStructure[code] = {
      name: name,
      code: code,
      hidden: true,
      id: newPageMeta._id
    }
    let updatePageStrRes = await this.metaService.updatePageStructure(newPageStructure);
    this.metaService.loadedPageStructure = newPageStructure;
    this.spinnerService.hide();
    return code;
  }

  getListIconAttri(type, primaryAttribute){
    let iconName = type == 'view' ? 'visibility' : type == 'delete' ? 'delete' : 'edit';
    console.log("list icon selected:", iconName)
    let attr = {
      __id: Date.now() + '_' + iconName,
      name: iconName,
      value: iconName,
      columnName: type ?  type : '',
      sortEnabled: false,
      show_hide: false,
      fieldType: 'icon',
      dataType: 'NA',
      widgetType: 'icon',
      eventType: "navigation"
    }

    if(iconName == 'delete'){
      attr["eventType"] = 'action';
      attr['actionConfig'] = {
        "event": "click",
        "action": "application",
        "actionMap": {
          "action": `${this.boxObjectId}/deleteById`,
          actionMode : "deleteById",
          "mapping": [{
            appField: {
              dataType: "string",
              name: "id"
            },
            mappingType: "sourceField",
            sourceField: {
              dataType: "string",
              name: primaryAttribute.name,
              __id: primaryAttribute.__id
            },
          }],
          successMessage: `The ${this.boxObjectId} action is successfull`,
          "boxConfigToken": this.baseMap.storage_token,
          "baseMap": {
            "box_id": this.baseMap.storage_box,
            "base_id":  this.baseMap._id
          },
          "boxId": "starch"
        }
      }
    } else {
      attr["navigationSettings"] = {
        "tooltip": `${type || 'Edit'} Detail`,
        "type": "internal",
        "pageCode": "",
        "enabled": true,
        "navFilterAttributes": [
           {
              "paramType": "attribute",
              "__id": primaryAttribute.__id,
              "dataType": "string",
              "filterable": true,
              "name": primaryAttribute.name,
              "sortable": true,
              "parameter": primaryAttribute.__id
           }
        ]
      }
    }
    return attr;
  }

  getButtonName(panelMap){
    let string = "";
    if(panelMap.searchPanel.checked)string += " Search,";
    if(panelMap.listPanel.checked) string += " List,";
    if(panelMap.detailsPanel.checked) string += " Details,";
    if(panelMap.formPanel.checked) string += " Form,";
    string = string.substring(0, string.length-1);
    return string;
  }

  formPanelCreatePanel(pageCode: any, layout?:any){
    let buttonWidget = WidgetManager.getWidget('button');
    let actionConfig = {
      "actions": [{
          "event": "click",
          "action": "navigation",
          "actionMap": {
            "mapping": [],
            "effectStyles": [],
            "widget": "1681889758834.1681889758834",
            "parameters": [],
            "pageType": "bloom_page",
            "page": pageCode
          }}]
    }
    buttonWidget.actionConfig = actionConfig;
    buttonWidget.config.buttonText.value = `New ${this.boxObjectId}`;
    buttonWidget.securityConfig = {"securities": []};
    buttonWidget.config.buttonIcon.value = true;
    buttonWidget.config.buttonIcon.type = "iconPicker";
    buttonWidget.gridX = 4;
    buttonWidget.config.buttonIcon.iconName = {
      displayName: "Material Icon Name",
      value:  "add_circle_outline"
    }
    buttonWidget.config.buttonType.value = "Stroked";

    let panel:any = new Panel(Date.now(), `create ${pageCode}`, buttonWidget);
    panel.alignment = layout || "flex-end";
    panel.selectedNow = false;
    return panel;
  }

  detailPanelBackButtonPanel(){
    let buttonWidget = WidgetManager.getWidget('button');
    let actionConfig = {
      "actions": [{
          "event": "click",
          "action": "navigation",
          "actionMap": {
            "pageType": "prev_page",
            "mapping": [],
            "parameters": []
          }
        }]
    }

    buttonWidget.actionConfig = actionConfig;
    buttonWidget.gridX = 4;
    buttonWidget.config.buttonText.value = "Back";
    buttonWidget.config.buttonType.value = "Basic";
    let panel:any = new Panel(Date.now(), `Detail Panel Top`, buttonWidget);
    panel.alignment = "flex-end";
    panel.selectedNow = false;
    return panel;
  }

  formPanelButton(){
    let buttonWidget = WidgetManager.getWidget('button');
    let actionConfig = {
      "actions": [{
          "event": "click",
          "action": "application",
          "actionMap": {
            "action": `${this.boxObjectId}/save`,
            "boxId": "starch",
            "actionMode": "save",
            "boxConfigToken": this.baseMap.storage_token,
            "baseMap": {
              "box_id": this.baseMap.storage_box,
              "base_id":  this.baseMap._id
            },
            "mapping": [],
            successMessage: `The ${this.boxObjectId} is successfully saved`

          }
        }]
    }

    buttonWidget.gridX = 4;
    buttonWidget.actionConfig = actionConfig;
    buttonWidget.config.buttonText.value = "Save";
    return buttonWidget;
  }


  formPanelPanelConfig(){
    let basicConfig:any = {
      "type": "formpanel",
      "layout": "flex-start",
      "widgets": [],
      "boxObjectId": this.boxObjectId,
      "formAttributes": [],
      "mode": "update",
      "attributeOptions": [],
      "actionFnOptions": [],
      "getFnOptions": [],
      "formPanelTitle": `Starch - ${this.boxObjectId} - Form`,
      "submitButtonTitle": "Submit",
      "primaryAttribute": {},
    }

    if(this.origin == "base"){
      basicConfig.boxId = "starch";
      basicConfig.baseMap = {
        "box_id": this.baseMap.storage_box,
        "base_id":  this.baseMap._id
      }
      basicConfig.baseId = this.baseMap._id;
      basicConfig.boxConfigToken =this.baseMap.storage_token;
    } else {
      basicConfig.boxId = this.boxId;
      basicConfig.boxName = this.baseMap?.options?.box_name || "";
      basicConfig.connectionId = this.baseMap._id;
    }
    return basicConfig;
  }

  searchPanelConfig(){
    let basicConfig:any = {
      "type": "searchpanel",
      "layout": "flex-start",
      "widgets": [],
      "headingExists": true,
      "boxId": "starch",
      "boxObjectId": this.boxObjectId,
      "searchAttributes": [],
      "userSelectSearchAtrribute": false,
      "sortAttributes": [],
      "userSelectSortAtrribute": false,
      "pageSize": 20,
      "searchPanelTitle": "Search",
      "searchButtonText": "Apply search",
      "attributeOptions": []
    }

    if(this.origin == "base"){
      basicConfig.boxId = "starch";
      basicConfig.baseMap = {
        "box_id": this.baseMap.storage_box,
        "base_id":  this.baseMap._id
      }
      basicConfig.baseId = this.baseMap._id;
      basicConfig.boxConfigToken =this.baseMap.storage_token;
    } else {
      basicConfig.boxId = this.boxId;
      basicConfig.boxName = this.baseMap?.options?.box_name || "";
      basicConfig.connectionId = this.baseMap._id;
    }
    return basicConfig;
  }

  detailsPanelConfig(){
    let basicConfig:any = {
      "type": "detailspanel",
      "layout": "flex-start",
      "widgets": [],
      "boxObjectId": this.boxObjectId,
      "detailsAttributes": [],
      "attributeOptions": [],
      "getFnOptions": [],
      "detailsPanelTitle": `Starch - ${this.data.name} - Details`,
      "submitButtonTitle": "Submit",
      "primaryAttribute": {},
      "showLabels": false,
      "selectedNow": false,
    }

    if(this.origin == "base"){
      basicConfig.boxId = "starch";
      basicConfig.baseMap = {
        "box_id": this.baseMap.storage_box,
        "base_id":  this.baseMap._id
      }
      basicConfig.baseId = this.baseMap._id;
      basicConfig.boxConfigToken =this.baseMap.storage_token;
    } else {
      basicConfig.boxId = this.boxId;
      basicConfig.boxName = this.baseMap?.options?.box_name || "";
      basicConfig.connectionId = this.baseMap._id;
    }
    return basicConfig;
  }


  listPanelConfig(primaryAttribute){
    let basicConfig:any = {
      "type": "listpanel",
      "layout": "flex-start",
      "widgets": [],
      "boxObjectId": this.boxObjectId,
      "listAttributes": [],
      "aditionalAttributesAllowed": false,
      "pageSize": 20,
      "loadInitialData": true,
      "paginationEnabled": true,
      "paginationType": "pagebypage",
      "defaultListSize": 10,
      "noDataMessage": "There is no data to be shown here",
      "attributeOptions": [],
      "getFnOptions": [],
      "listPanelTitle": `Starch - ${this.data.name} - Listing`,
      "viewTypes": {
        "views": [
          "table",
          "card",
          "board"
        ],
        "defaultView": "table",
        "boardStatusColumn": "",
        "userCanChoose": true,
        "canSelectFields": true,
        "table": {
          "name": "table",
          "displayName": "Table",
          "userCanChoose": true,
          "icon": "list"
        },
        "card": {
          "name": "card",
          "displayName": "Card",
          "userCanChoose": true,
          "icon": "grid_on"
        },
        "board": {
          "name": "board",
          "displayName": "Board",
          "userCanChoose": false,
          "icon": "view_kanban"
        }
      },
      "primaryAttribute": primaryAttribute,
      "filter": {
        "filterEnabled": false,
        "filterItems": []
      },
      "sort": {
        "sortEnabled": false,
        "sortAttributes": []
      },
      "listWidgetSet": {},
      "userFilterEnabled": true,
      "userSortEnabled": true
    }

    if(this.origin == "base"){
      basicConfig.boxId = "starch";
      basicConfig.baseMap = {
        "box_id": this.baseMap.storage_box,
        "base_id":  this.baseMap._id
      }
      basicConfig.baseId = this.baseMap._id;
      basicConfig.boxConfigToken =this.baseMap.storage_token;
    } else {
      basicConfig.boxId = this.boxId;
      basicConfig.boxName = this.baseMap?.options?.box_name || "";
      basicConfig.connectionId = this.baseMap._id;
    }
    return basicConfig;
  }

  generateWidgetSet(listAttributes){
    let newWidgetSet: any = {}

    for (let i = 0; i < listAttributes.length; i++) {
      let widgetType: string = listAttributes[i].widgetType == 'NA' ? listAttributes[i].fieldType : listAttributes[i].widgetType;
      const attrId = listAttributes[i].__id;
      newWidgetSet[attrId] = WidgetManager.getWidget(widgetType || 'label')
    };
    console.log("widget set prepared", newWidgetSet)
    return newWidgetSet
  }

}


