import { Component, Input, OnInit, ViewChild, Inject, Output, EventEmitter, OnChanges, SimpleChanges, Directive, HostListener, AfterViewInit, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router'
import { MatMenuTrigger } from '@angular/material/menu';

import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ActionManager } from 'src/app/bloom/models/Action/ActionManager';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { Subject } from 'rxjs';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';

@Directive({ selector: '[outerCover]' })
export class resize {
  @HostListener('mousedown', ['$event'])
  onClick(target) {
    console.log("target clicked", target)
  }
}

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent extends BaseWidgetComponent implements OnInit, OnChanges, AfterViewInit {

  @Output() onExecuteAction = new EventEmitter<any>();
  @Output() raiseButtonClicked = new EventEmitter<any>();

  buttonStyle: string = ''
  contextMenuActions: any = [];
  styles: any;
  element: any;
  parent: any;
  mouseDownPos: any = 0;
  initialWidth: any = 0;
  securityMap: any = {};
  availableItems: any = []

  private destroy:any = new Subject();

  //ViewChid
  @ViewChild('buttonEditInput', { static: false }) private _buttonEditInput: ElementRef;
  @ViewChild('menuTrigger', { static: false }) private _buttonTrigger: MatMenuTrigger;

  //flags
  buttonEditMode: boolean = false;
  hoveredNow: boolean = false;
  oldStaticOption: any;
  // grabber: boolean = false;
  // oldX: any = 0;
  // x: any = 0;
  // width: any = 0

  constructor(public router: Router,
    public dialog: MatDialog,
    public tokenUtil: TokenUtil,
    public http: HttpClient,
    public elementRef: ElementRef,
    public actionManager: ActionManager,
    public metaService: MetaService,
    public resourcePermissionService: ResourcePermissionService,
    private widgetUtilityService: WidgetUtilityService,
    public pageService: PageService
  ) {
    super(metaService, pageService, resourcePermissionService)
    // console.log("tags constructor hit", this.widgetMeta)
  }

  ngAfterViewInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.widgetMeta?.currentValue){
      this.generateAvailableOptions()
      this.setContextActions()
    }

    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }

    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this._buttonTrigger && this._buttonTrigger.menuOpen) {
        this._buttonTrigger.closeMenu()
      }
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    // console.log("init button")
    this.securityMap = super.checkAccess();
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngDoCheck(){
    let oldOpts = JSON.stringify(this.oldStaticOption);
    let newOpts = JSON.stringify(this.widgetMeta.config.availableOptions.staticOptions)
    if(oldOpts != newOpts){
      this.oldStaticOption = this.widgetMeta.config.availableOptions.staticOptions;
      this.generateAvailableOptions(false)
    }
  }

  @ViewChild('menuTrigger') buttonMenuTrigger: MatMenuTrigger
  @ViewChild('outerCover') outerCover: ElementRef;


  //----------------------------METHODS-------------------------

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "edit",
        "bold",
        "underline",
        "italic",
        "color",
        "fontSize",
        "fontFamily",
        "buttonType"
      ],
      tagType: {
        value: this.widgetMeta?.config.tagType.value,
        availableTypes: this.widgetMeta?.config.tagType.availableTypes,
        type: this.widgetMeta?.config.tagType.type
      }
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("on action", event)
    switch (event.actionType) {
      case "delete":
        this.onDelete()
        break;
      case "updateStyles":
        if (event?.data) {
          this.widgetMeta = event.data;
          super.generateStyles()
          // this.newWidgetMeta.emit(this.widgetMeta)
        }
        break;
      case "customPropertyUpdate":
        console.log("RETURNED FROM MENU", event)
        this.widgetMeta.config[event.propertyName].value = event.data
        // this.newWidgetMeta.emit(this.widgetMeta)
        break;
      case 'settingsChanged':
        this.widgetMeta = event.data
        this.generateAvailableOptions()
          // this.newWidgetMeta.next(this.widgetMeta)
        this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        break;

      default:
        break;
    }
  }

  onOuterClick() {
    console.log("outer click")
    if (this.builderMode) {
      this.toggleWidgetSelection()
    }
  }

  mouseEnter(e) {
    this.executeAction(e)
  }

  mouseLeave(e) {
    this.executeAction(e)
  }

  onClick(event: Event, value?) {
    console.log("tag button clicked", event, this.widgetMeta.actionConfig)
    this.executeAction(event, value)
  }

  executeAction(e, value?) {
    if(!this.widgetMeta.actionConfig || !this.widgetMeta.actionConfig.actions) return
    if (!this.builderMode) this.actionManager.executeActions(this.widgetMeta.actionConfig.actions, e, {value: value?.value});
    //  this.actionManager.getAction(this.widgetMeta.actionConfig.action).doAction(this.widgetMeta.actionConfig, e)
  }

  toggleWidgetSelection() {
    // toggle widget selection
    if (this.selectedWidgetId == this.widgetMeta.id) {
      console.log("unselecting")
      this.widgetSelection.emit(-1);
    } else {
      this.widgetSelection.emit(this.widgetMeta.id)
    }
  }

  async generateAvailableOptions(noRefetch: boolean = false){
    // console.log("generate available options hit", noRefetch)
    // console.log("this.widgetMeta.config", this.widgetMeta.config)
    let staticOptions: any[] = this.widgetMeta.config.availableOptions.staticOptions
    
    let rawDynamicOptions: any[] = []
    let dynamicOptionItems: any[] = []
    if(noRefetch){
      dynamicOptionItems = this.availableItems.filter(opt => opt.type == 'dynamic')
      // console.log("preserved dynamic options", dynamicOptionItems)
    }else{
      // rawDynamicOptions = await this.widgetUtilityService.fetchDynamicOptions(this.widgetMeta, this.builderMode)
      dynamicOptionItems = this.widgetUtilityService.processDynamicOptions(rawDynamicOptions, this.widgetMeta)
    }

    if(this.builderMode){
      staticOptions.map(opt => opt['type'] = 'static')
      dynamicOptionItems.map(opt => opt['type'] = 'dynamic')
    }

    this.availableItems = []
    this.availableItems = this.availableItems.concat(staticOptions)
    this.availableItems = this.availableItems.concat(dynamicOptionItems)

    // console.log("options", this.availableItems)
    // this.emitUserInput()
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.buttonMenuTrigger?.closeMenu();
  }

  

  timer: any = 0;
  delay = 200;
  prevent = false;

  tagItemClicked(event: Event, value?) {
    // event.stopPropagation();
    // this.raiseButtonClicked.emit(event);
    let that = this;
    that.onClick(event, value);
    console.log("actual button click detected");
    this.timer = setTimeout(function () {
      console.log("onclick fired")
      // if (!that.prevent) {
      //   that.builderMode ? that.buttonMenuTrigger.openMenu() : that.buttonMenuTrigger.closeMenu();
      // }
      that.prevent = false;
    }, that.delay);
  }

  onDoubleButtonClicked() {
    console.log('Double Click');
    if (!this.builderMode) {
      this.buttonEditMode = false;
      return;
    }
    clearTimeout(this.timer);
    this.prevent = true;
    this.turnOnEditMode();
  }

  turnOnEditMode() {
    this.buttonEditMode = true;
    setTimeout(() => { this._buttonEditInput.nativeElement.focus(); }, 0);
  }

  onFocusOut(event: any) {
    this.buttonEditMode = false;
  }

}
