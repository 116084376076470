import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import * as Moment from 'moment-timezone';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-trigger-scheduler',
  templateUrl: './trigger-scheduler.component.html',
  styleUrls: ['./trigger-scheduler.component.scss']
})
export class TriggerSchedulerComponent implements OnInit {

  @Input() editMode:boolean;
  @Input() "flowMap": any;
  @Input() "hideRibbon": boolean = false;
  @Input() "intervalTypes": any[];
  @Input() "schedulerInfo"?: any;
  @Input() "isOnlyInterval"?: boolean = false;
  @Output() "onConfigure" = new EventEmitter<any>();
  @Output() "sourceFields" = new EventEmitter<any>();

  myControl = new UntypedFormControl();

  filteredTimeZones: Observable<string[]>;
  timeZones: any = Moment.tz.names();
  schedulerSourceFields:any = [
    { dataType: "string", name: "Current Date Time", __id: "system.datetime" },
    { dataType: "string", name: "Pretty Date", __id: "system.pretty_date" },
    { dataType: "string", name: "Pretty Time", __id: "system.pretty_time" },
    { dataType: "string", name: "Day of Week", __id: "system.pretty_day_of_week" },
    { dataType: "string", name: "Year", __id: "system.date_year" },
    { dataType: "string", name: "Month", __id: "system.date_month" },
    { dataType: "string", name: "Day", __id: "system.date_day" },
    { dataType: "string", name: "Hour", __id: "system.time_hour" },
    { dataType: "string", name: "Minute", __id: "system.time_minute" },
    { dataType: "string", name: "Second", __id: "system.time_second" }
  ]

  constructor(
    private ngZone: NgZone,
  ) { }


  // intervalTypes: any[] = [
  //   {}
  // ]

  weekdays: any

  times: any[] = [
    { id: "0", name: "Midnight" },
    { id: "1", name: "1am" },
    { id: "2", name: "2am" },
    { id: "3", name: "3am" },
    { id: "4", name: "4am" },
    { id: "5", name: "5am" },
    { id: "6", name: "6am" },
    { id: "7", name: "7am" },
    { id: "8", name: "8am" },
    { id: "9", name: "9am" },
    { id: "10", name: "10am" },
    { id: "11", name: "11am" },
    { id: "12", name: "Noon" },
    { id: "13", name: "1pm" },
    { id: "14", name: "2pm" },
    { id: "15", name: "3pm" },
    { id: "16", name: "4pm" },
    { id: "17", name: "5pm" },
    { id: "18", name: "6pm" },
    { id: "19", name: "7pm" },
    { id: "20", name: "8pm" },
    { id: "21", name: "9pm" },
    { id: "22", name: "10pm" },
    { id: "23", name: "11pm" }
  ]

  days: any[] =  [
    { id: "7", name: "Sun" },
    { id: "1", name: "Mon" },
    { id: "2", name: "Tue" },
    { id: "3", name: "Wed" },
    { id: "4", name: "Thu" },
    { id: "5", name: "Fri" },
    { id: "6", name: "Sat" }
  ]

  minutes: any[] =  [
    { id: "5", name: "5" },
    { id: "10", name: "10" },
    { id: "15", name: "15" },
    { id: "20", name: "20" },
    { id: "30", name: "30" }
  ]

  intervalTypeMap: any = {
    minutely: {
      name: "Minutely",
      template : '*/${minute} * * * *',
      minutes: this.minutes
    },
    hourly: {
      name: "Hourly",
      template : '0 ${hour} * * ${day}',
      days: this.days
    },
    daily: {
      name: "Daily",
      template : '${minute} ${hour} * * ${day}',
      times: this.times,
      days: this.days
    },
    weekly: {
      name: "Weekly",
      template : '${minute} ${hour} * * ${day}',
      times: this.times,
      days: this.days
    },
    monthly: {
      name: "Monthly",
      template : '${minute} ${hour} ${day} * *',
      times: this.times,
      days:  [
            { id: "1", name: "1" },
            { id: "2", name: "2" },
            { id: "3", name: "3" },
            { id: "4", name: "4" },
            { id: "5", name: "5" },
            { id: "6", name: "6" },
            { id: "7", name: "7" },
            { id: "8", name: "8" },
            { id: "9", name: "9" },
            { id: "10", name: "10" },
            { id: "11", name: "11" },
            { id: "12", name: "12" },
            { id: "13", name: "13" },
            { id: "14", name: "14" },
            { id: "15", name: "15" },
            { id: "16", name: "16" },
            { id: "17", name: "17" },
            { id: "18", name: "18" },
            { id: "19", name: "19" },
            { id: "20", name: "20" },
            { id: "21", name: "21" },
            { id: "22", name: "22" },
            { id: "23", name: "23" },
            { id: "24", name: "24" },
            { id: "25", name: "25" },
            { id: "26", name: "26" },
            { id: "27", name: "27" },
            { id: "28", name: "28" },
            { id: "29", name: "29" },
            { id: "30", name: "30" },
            { id: "31", name: "31" }
          ],
      first: this.days,
      last: this.days

    }
  }

  ngOnInit(): void {
    //set default interval types if not passed as input param.
    if(!this.intervalTypes) {
      this.intervalTypes = [
        { name: "Minutely", value: "minutely" },
        { name: "Hourly", value: "hourly" },
        { name: "Daily", value: "daily" },
        { name: "Weekly", value: "weekly" },
        { name: "Monthly", value: "monthly" }
      ]
    }

    if(!this.flowMap.trigger.schedulers) {
      this.flowMap.trigger.schedulers = [];
      this.flowMap.trigger.schedulers.push({
        schedule_type: "interval"
      })
      this.onSelectSchedule(this.flowMap.trigger.schedulers[0]);
    }
    this.sourceFields.emit(this.schedulerSourceFields);
  }

  setTimeZoneOptions() {
    this.filteredTimeZones = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    value = value ? value : ""
    const filterValue = value.toLowerCase();
    return this.timeZones.filter(option => option.toLowerCase().includes(filterValue));
  }

  getMyTimeZone(schedule: any) {
    schedule.timezone = Moment.tz.guess();
    this.ngZone.run(() => { })
  }

  onSelectSchedule(schedule: any){

    console.log("schedule", schedule)

    if(schedule.schedule_type == 'specific_time'){
      console.log("schedule.schedule_type", schedule.schedule_type)
      if(!schedule.specific_time) {
        schedule.specific_time = {};
      }

      if(schedule.specific_time.date)
      schedule.date_time = setDateTime(new Date(schedule.specific_time.date), schedule.specific_time.time || "00:00")

    }

    if(!schedule.timezone) this.getMyTimeZone(schedule);

    if(schedule.schedule_type == 'interval'){

      if(!schedule.interval) {
        schedule.interval = {};
        schedule.interval.day_of_month = "5";
        schedule.interval.hours_between = {min: 9, max: 18};
        schedule.interval.days_of_week = ["1", "2", "3", "3", "4", "5"];
        schedule.interval.time = "09:00";
        schedule.interval.day_of_week = "3";
      }


      let te = new TemplateEngine();
      let options:any = {}

      switch(schedule.interval_type) {

        case "hourly": {
          options = {
            day: schedule?.interval?.days_of_week ? schedule?.interval?.days_of_week.join(",") : "*",
            hour: schedule?.interval?.hours_between ? `${schedule?.interval?.hours_between?.min}-${schedule?.interval?.hours_between?.max}` : "*"
          };
          break;
        }
        case "daily": {
          options = {
            day: schedule?.interval?.days_of_week ? schedule?.interval?.days_of_week.join(",") : "*",
            hour: schedule?.interval?.time.split(":")[0] || "0",
            minute: schedule?.interval?.time.split(":")[1] || "0"
          }
          break;
        }
        case "weekly": {
          options = {
            day: schedule?.interval?.day_of_week || "1",
            hour: schedule?.interval?.time.split(":")[0] || "0",
            minute: schedule?.interval?.time.split(":")[1] || "0"
          };
          break;
        }
        case "monthly": {
          options = {
            day: schedule?.interval?.day_of_month || "1",
            hour: schedule?.interval?.time.split(":")[0] || "0",
            minute: schedule?.interval?.time.split(":")[1] || "0"
          };
          break;
        }
        case "minutely": {
          options = {
            minute: schedule?.interval?.minute
          };
          break;
        }
        default: {
          break;
        }
      }

      //replace with template engine
      if(schedule.interval_type) schedule.cron_code = te.fillAny(this.intervalTypeMap[schedule.interval_type].template, options);
    }

    this.onConfigure.emit(this.flowMap.trigger.schedulers);
    this.sourceFields.emit(this.schedulerSourceFields);
  }

  onChangeHourRange(e, schedule){
    console.log("onChangeHourRange", e)
    schedule.interval.hours_between = e;
  }

}

function setDateTime(date, time) {
  console.log("date", date)
  var splitedTime = time.split(":")
  var hours = splitedTime[0] || "00"
  var minutes = splitedTime[1] || "00"

  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds("00");

  return date;
}
