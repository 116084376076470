<mat-spinner *ngIf="spinner" color="warn" class="centerOfAll" [diameter]="50"
  fxLayoutAlign="center center"></mat-spinner>
<!--New/Edit Connection-->
<mat-card appearance="outlined" class="card-layout" fxLayout="column" fxLayoutGap="50px">
  <div *ngIf="!['ZOHOCRM','ZOHOBOOKS','HYPERLIST'].includes(clientPlatformService.clientType)" fxLayout="column"
    fxLayoutAlign="end end">
    <a mat-button href="https://appiworks.jivrus.com/support" color="primary" target="_blank">
      Contact Support <mat-icon color="primary">support</mat-icon>
    </a>
    <a mat-button *ngIf="selectedBox?.helpLink" [href]="selectedBox?.helpLink" color="primary" target="_blank">
      Help <mat-icon color="primary">help</mat-icon>
    </a>
  </div>
  <div>
    <div fxLayoutAlign="end center">
      <div *ngIf="!isNewConnection && connection?.status == 'DRAFT'" class="ribbondraft">
        <span>{{connection.status}}</span>
      </div>
      <div *ngIf="!isNewConnection && connection?.status == 'ACTIVE'" class="ribbonactive">
        <span>{{connection.status}}</span>
      </div>
      <div *ngIf="!isNewConnection && connection?.status == 'FAILED'" class="ribbonfailed">
        <span>{{connection.status}}</span>
      </div>
    </div>
    <h2 fxLayoutAlign="center start">{{ navigation?.extras?.state?.connTitle || (isNewConnection ? 'New' : 'Edit') }}
      Connection</h2>
    <p style="text-align: center;" *ngIf="navigation?.extras?.state?.connDescription" fxLayoutAlign="center start">
      {{navigation?.extras?.state?.connDescription}}</p>
  </div>
  <div fxLayoutAlign="center center" fxLayout="column" flex="100%">
    <form [formGroup]="connectionFormGroup" class="full-width ull-width-form" fxLayoutAlign="center center" fxLayout="column" [class.hidden]="hideFormInputs">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="nameCtrl" required />
        <mat-error *ngIf="connectionFormGroup.controls.nameCtrl.errors?.required">
          Please Enter Your Name
        </mat-error>
        <mat-error *ngIf="connectionFormGroup.controls.nameCtrl.errors?.minlength">
          Name must be at least 4 characters long.
        </mat-error>
      </mat-form-field>
      <br />
      <mat-form-field>
        <mat-label>App Name</mat-label>
        <img matPrefix *ngIf="connectionFormGroup?.controls?.appNameCtrl?.value?.['logo']"
          src="{{ connectionFormGroup.controls.appNameCtrl.value['logo'] }}" class="logo-size" />
        <input matInput formControlName="appNameCtrl" [matAutocomplete]="auto" required />
        <!-- Hide close icon if disableAppSelection is true -->
        <button matSuffix mat-icon-button
          *ngIf="connectionFormGroup.controls.appNameCtrl.value && !(disableAppSelection)" (click)="resetAppName()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="connectionFormGroup.controls.appNameCtrl.errors?.required">
          App Name is required
        </mat-error>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayBoxFuntion"
          (optionSelected)="onChangeBox(connectionFormGroup.controls.appNameCtrl.value);">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            <img [src]="option.logo" class="image-size" />&nbsp;
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
    <br />
    <form [formGroup]="boxConfigForm" class="form-field full-width" (ngSubmit)="onConnect()" fxFlexAlign="center center"
      fxLayout="column">
      <div [class.hidden]="hideFormInputs">
        <div class="full-width">
          <div *ngIf="authTypeValues.length > 1" class="auth-type-one" fxLayout="row" fxLayout="start center"
            fxLayoutGap="2%">
            <div>Select Authentication Type:</div>
            <mat-button-toggle-group [formControl]="authenticationTypeSelect"
              (change)="onSelectionChangedAuthenticationType()">
              <mat-button-toggle *ngFor="let option of authTypeValues" [value]="option">
                {{ authTypes[option]?.name || option }}</mat-button-toggle>
            </mat-button-toggle-group>
            <!--<mat-error *ngIf="authenticationTypeSelect.errors?.required">
              Please Select Authentication Type
            </mat-error>-->
          </div>
          <div *ngIf="authTypeValues.length == 1" class="auth-type-one" fxLayout="row" fxLayout="start center"
            fxLayoutGap="2%">
            <div>Authentication Type:</div>
            <mat-card appearance="outlined" *ngFor="let option of authTypeValues"><mat-card-content>{{
                authTypes[option]?.name || option }}</mat-card-content></mat-card>
            <!--<mat-error *ngIf="authenticationTypeSelect.errors?.required">
              Please Select Authentication Type
            </mat-error>-->
            <!-- <mat-spinner *ngIf="!connectionService.authorizationUrl" diameter="30" color="primary" style="margin-left: 5px;"></mat-spinner> -->
          </div>
        </div>
        <br *ngIf="authTypeValues.length > 0" />
        <div *ngIf="isConfig" class="full-width" fxLayout="column" fxLayoutGap="1.5%">
          <div *ngFor="let descriptor of boxConfig" [formGroup]="configForm" class="full-width" fxLayout="column"
            fxLayoutAlign="center center">

            <!-- only handles primary configs, i.e. required to make connection immedietly -->
            <ng-container *ngIf="!descriptor.relation">
              <span fxLayout="row" fxLayout.xs="column" *ngIf="descriptor?.widget;else boxConfig" class="widget-width"
                fxLayoutGap="2px">
                <mat-form-field class="align-form-field">
                  <mat-icon matPrefix color="primary">
                    {{ descriptor.icon }}
                  </mat-icon>
                  <mat-label class="text-capitalize">
                    {{ descriptor.name }}
                  </mat-label>
                  <input matInput formControlName="{{descriptor.__id}}" type="{{ descriptor.dataType }}" maxlength="255"
                    [id]="descriptor.__id" [required]="descriptor?.required||false" />
                  <mat-error *ngIf="configForm.controls[descriptor.__id].errors?.required" class="text-capitalize">
                    Please Enter {{ descriptor.name }}
                  </mat-error>
                </mat-form-field>

                <span *ngFor="let widget of descriptor.widget.list">
                  <div [ngSwitch]="descriptor.widget[widget].__id">
                    <div *ngSwitchCase="'googlepicker'">
                      <googlepicker [matTooltip]="'Open Google File Picker to choose '+selectedBox.name"
                        [variants]="descriptor.widget[widget].variants" [InputId]="descriptor.__id"
                        (setFileId)="setFileId($event)"></googlepicker>
                      <a mat-icon-button type="button" *ngIf="configForm.controls[descriptor.__id].value"
                        [matTooltip]="'Open '+ selectedBox.name +' in a new tab'"
                        [href]="navigationLink(configForm.controls[descriptor.__id].value)" target="_blank">
                        <mat-icon>open_in_new</mat-icon>
                      </a>
                    </div>
                    <span *ngSwitchDefault></span>
                  </div>
                </span>
              </span>
            </ng-container>
            <ng-template #boxConfig [ngSwitch]="descriptor.dataType">
              <ng-container *ngSwitchCase="'string'">
                <ng-container *ngTemplateOutlet="input"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'password'">
                <ng-container *ngTemplateOutlet="input"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'number'">
                <ng-container *ngTemplateOutlet="input"></ng-container>
              </ng-container>
              <ng-template #input>
                <mat-form-field class="form-input align-form-field"
                  [fxHide]="descriptor?.hidden||descriptor.__id == 'accesstoken' || descriptor.__id == 'authType'||false">
                  <mat-icon matPrefix color="primary">
                    {{ descriptor.icon }}
                  </mat-icon>
                  <mat-label class="text-capitalize">
                    {{ descriptor.name }}
                  </mat-label>
                  <input matInput formControlName="{{descriptor.__id}}" type="{{ descriptor.dataType }}" name="value"
                    [required]="descriptor?.required||false" [disabled]="descriptor?.readonly||false" />
                  <mat-error *ngIf="configForm.controls[descriptor.__id].errors?.required" class="text-capitalize">
                    Please Enter {{ descriptor.name }}
                  </mat-error>
                </mat-form-field>
              </ng-template>
              <section class="cb-section" *ngSwitchCase="'boolean'">
                <mat-checkbox formControlName="{{descriptor.__id}}" name="value" class="cb-margin"
                  labelPosition="before" class="text-capitalize">
                  {{ descriptor.name }}
                </mat-checkbox>
              </section>
              <ng-container *ngSwitchCase="'object'">
                <mat-form-field class="form-input align-form-field align-items-center">
                  <mat-icon matPrefix color="primary" class="align-items-center">
                    {{ descriptor.icon }}
                  </mat-icon>
                  <mat-label class="text-capitalize">
                    {{ descriptor.name }}
                  </mat-label>
                  <textarea matInput placeholder="JSON" (change)="objectInputChange($event,descriptor.__id)"
                    [required]="descriptor?.required||false" [disabled]="descriptor?.readonly||false"></textarea>
                  <mat-error *ngIf="configForm.controls[descriptor.__id].errors?.required" class="text-capitalize">
                    Please Enter {{ descriptor.name }} in JSON Format!!
                  </mat-error>
                </mat-form-field>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="full-width" fxLayoutAlign="center center" *ngIf="isAuthorize">
        <div fxLayout="row" fxLayoutAlign="start center">
          <a *ngIf="!isRevokeAuthorization && connectButton && connectButton.type == 'image_button'" mat-stroked-button
            color="primary" fxLayout="row" fxlayoutAlign="center center" [href]="connectionService.authorizationUrl"
            [disabled]="!connectionService.authorizationUrl" target="_blank" rel="opener" (click)="listenConnection()">
            <div fxFlex="10" fxlayoutAlign="center center"><img matPrefix src="{{connectButton.image}}"
                class="logo-size" /></div>
            <div fxFlex="90" fxlayoutAlign="center center">Authorize with {{
              connectionFormGroup.controls.appNameCtrl.value["name"] }}</div>
          </a>
          <div *ngIf="!isRevokeAuthorization && connectButton && connectButton.type == 'image_button'">
            <mat-spinner *ngIf="!connectionService.authorizationUrl" diameter="20" color="primary"
              style="margin-left: 5px;"></mat-spinner>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <a mat-flat-button color="primary" [href]="connectionService.authorizationUrl" (click)="listenConnection()"
            [disabled]="!connectionService.authorizationUrl" *ngIf="!isRevokeAuthorization && !connectButton"
            target="_blank" rel="opener">
            Authorize with {{ connectionFormGroup.controls.appNameCtrl.value["name"] }}
          </a>
          <div *ngIf="!isRevokeAuthorization && !connectButton">
            <mat-spinner *ngIf="!connectionService.authorizationUrl" diameter="20" color="primary"
              style="margin-left: 5px;"></mat-spinner>
          </div>
        </div>
        <a mat-flat-button color="warn" *ngIf="isRevokeAuthorization" (click)="deauthorizeConnection()">
          Disable Authorization
        </a>
      </div>


      <div *ngIf="collectSecondaryConfigs" class="full-width" fxLayout="row" fxLayoutAlign="center center">
        <div class="full-width" style="width: 80%;">
          <div style="height: 1.5rem;"></div>
          <div style="font-size: 1.3rem;">Please provide additional configurations</div>
          <!-- <div style="height: .5rem;"></div> -->
          <shared-parameter-inputs [action]="action" [connection]="connection" [existingInputs]="availableParams"
            [optionalInputs]="availableParams"
            (inputParameter)="secondaryConfigReceived($event)"></shared-parameter-inputs>
        </div>
      </div>

      <mat-card appearance="outlined" *ngIf="isWhitelistRequired">
        <mat-card-content>
          <h4><strong><span style="color: #f44336;">Warning!</span> IP Address needs to be whitelisted</strong></h4>
          <p>Your database requires the following IP range should be whitelisted in your cloud/ on-premise so that
            AppiWorks Infrastructure can connect with it. </p>
          <p><strong>35.238.134.134</strong></p>
        </mat-card-content>
      </mat-card>

      <br />
      <div fxLayout="row wrap" fxLayout.lt-sm="column" class="full-width" fxLayoutAlign="center center"
        fxLayoutGap="10px">
        <button *ngIf="!isAuthorize" mat-flat-button color="primary" type="submit"
          [disabled]="!showValidateButton && (isAuthorize || !isSecondaryValid)">
          {{ showValidateButton ? "Validate" : "Connect" }}
        </button>
        <button *ngIf="isAuthorize" mat-stroked-button color="primary" matTooltip="Save Connection"
          (click)="insertOrUpdateConnection()" type="button" [disabled]="!isRevokeAuthorization">
          Save
        </button>
        <button *ngIf="!isAuthorize" mat-stroked-button color="primary" matTooltip="Save Connection"
          (click)="insertOrUpdateConnection()" type="button">
          Save
        </button>
        <!-- Hide back button if disableAppSelection is true -->
        <button *ngIf="!disableAppSelection" mat-stroked-button (click)="closeNewRecord()" type="button">
          Back
        </button>
      </div>
      <div fxLayout="row wrap" fxLayout.lt-sm="column" class="full-width" fxLayoutAlign="center center"
        fxLayoutGap="10px">
        <span
          *ngIf="connection && (!connection?.box_token || connection?.box_token == '') && authenticationTypeSelect?.value == 'oauth2'"
          style="color: grey;" #infoElement><strong>Note:</strong> Ensure that connection is authorized before
          saving.</span>
      </div>
    </form>
  </div>
</mat-card>