<div id="my-canvas">
<div class="wrapper" *ngIf="viewMode == false"  >
  <!-- <div class="header">
    <button mat-stroked-button color="primary" (click)="goBack()" fxLayoutAlign="center center">
      <mat-icon>west</mat-icon> Back
    </button>
  </div>
  <div style="height: 1.5rem"></div> -->

  <div class="header" fxLayoutAlign="center center">
    Submission success page
  </div>
  <div style="height: 1.5rem"></div>

  <div style="width: 100%">
    <mat-radio-group color="primary" (change)="successActionSelected($event)" style="width: 100%" fxLayoutAlign="center center" fxLayoutGap="1rem">
      <div fxFlex="50" class="radioButtonWrap" [ngClass]="{active: successPageConfig.actionType == 'thankyou'}" fxLayoutAlign="center center">
        <mat-radio-button class="actionTypeRadio" [checked]="successPageConfig.actionType == 'thankyou'" value="thankyou">Show thank you page</mat-radio-button>
      </div>
      <div fxFlex="50" class="radioButtonWrap" [ngClass]="{active: successPageConfig.actionType == 'external'}" fxLayoutAlign="center center">
        <mat-radio-button class="actionTypeRadio" [checked]="successPageConfig.actionType == 'external'" value="external">Navigate to external URL</mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  <div style="height: 1.5rem"></div>
  <div>
    <mat-checkbox (change)="onCeleberationChange($event)" [(ngModel)]="successPageConfig.isCelebrate"> Celebrate successful submission </mat-checkbox>
  </div>

  <div *ngIf="successPageConfig.actionType == 'thankyou' && isReady" fxLayoutAlign="center flex-start">
    <div fxFlex="90">
      <app-richtextinput
        style="width: 100%"
        [widgetMeta]="richtextWidgetMeta"
        (userInputReceived)="thankYouContentReceived($event)">
      </app-richtextinput>
    </div>

    <div fxFlex="10">
      <app-field-picker
        [inputFieldMap]="{
          list:['sourceFields'],
          sourceFields: {
            id:'sourceFields',
            displayName: 'Form Fields',
            fields: fields,
            options:{}
          }
        }"
        (selectedFields)="insertCursor($event)"
      ></app-field-picker>
    </div>

  </div>
  <div *ngIf="successPageConfig.actionType == 'external'">
    <mat-form-field style="width: 100%">
      <mat-label>Type or paste external URL</mat-label>
      <input matInput [(ngModel)]="successPageConfig.externalUrl">
      <mat-icon matSuffix>link</mat-icon>
      <!-- <mat-hint *ngIf="isValidExternalUrl == true" style="color: green">Looks good!</mat-hint>
      <mat-hint *ngIf="isValidExternalUrl == false" style="color: red">Invalid URL</mat-hint> -->
    </mat-form-field>
  </div>
  <div style="height: 1.5rem"></div>
  <div fxLayoutAlign="center center">
    <button mat-raised-button color="primary" (click)="saveConfig()" fxLayoutAlign="center center">
      Save <mat-icon>done</mat-icon>
    </button>
  </div>
</div>

<div class="wrapper" *ngIf="viewMode && isReady">
  <app-richtextinput style="width: 100%" [widgetMeta]="richtextWidgetMeta"></app-richtextinput>
</div>
</div>

<div *ngIf="viewMode && isReady">
  <div style="height: 10vh;"></div>
  <div  style="color: gray; font-size: x-small;"  fxLayoutAlign="center center">
    This form is powered by AppiWorks Form. Make your &nbsp; <a style="cursor: pointer;" href="{{environment.BASE_URL}}/form" > own form </a>
  </div>
  <div style="height: 5px;"></div>
  <div fxLayout="row" fxLayoutAlign="center center" >
        <a href="{{environment.BASE_URL}}" target="_blank"  style="cursor: pointer;" aria-label="Home" fxLayout fxLayoutAlign="center center">
          <img src="https://storage.googleapis.com/jivrus-web-images/products/appiworks/appiworks-logo.png"
            class="logo" loading="lazy" alt="AppiWorks Logo 3D"/>
        </a>
        <div fxFlex="2"></div>
        <a href="{{environment.BASE_URL}}/form" target="_blank"  style="cursor: pointer;" aria-label="Form" fxLayout fxLayoutAlign="center center">
          <img src="https://storage.googleapis.com/jivrus-web-images/products/appiworks/form-builder/aw-form-builder-logo.png"
            class="logo" loading="lazy" alt="AppiWorks Logo 3D"/>
        </a>
  </div>
</div>


