<!-- <div>page component - ready: {{ready}} builderMode: {{builderMode}}</div> -->
<!-- <mat-spinner
  *ngIf="pageService.spinner"
  color="warn"
  class="centerOfAll"
  [diameter]="50"
  fxLayoutAlign="center center"
></mat-spinner> -->

<!-- <div style="width: 100%;" *ngIf="ready === false && (spinner || pageService.spinner)"> -->
<div style="width: 100%;" *ngIf="ready === false && (spinner || pageService.spinner)">
  <!-- <spinner [type]="'skeleton'" [selfSpin]="true" [skeletonSpinnerGridMap]="skeletonSpinnerGridMap"></spinner> -->
  <spinner [type]="'skeleton'" [selfSpin]="true" [skeletonPresetCode]="'page-loader-2'"></spinner>
</div>

<!-- <div *ngIf="ready === false" class="full" fxLayout fxLayoutAlign="center center" fxFlexFill>
  <spinner size="la-2x" type="ball-atom" center="true"></spinner>
</div> -->
<ng-container *ngIf="builderMode; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>

  <div *ngIf="ready" class="page-wrap" cdkDropList (cdkDropListDropped)="panelReorder($event)"
    (click)="panelDeselected($event)">
    <div fxLayout="row wrap" fxLayoutAlign="start center" cdkDropListGroup>
      <div fxFlex="100%" *ngFor="let panel of currentPageMeta.panels; let i = index" class="panel" cdkDrag [cdkDragDisabled]="!builderMode">
        
        <div class="new-panel-placeholder" *ngIf="pageService.dragNewPanelPlaceholder.newIndex === i"></div>
        
        <app-panel
          (onPanelSelect)="onPanelSelect($event)"
          (onPanelMouseenter)="onPanelMouseenter($event)"
          (onPanelMouseleave)="onPanelMouseleave($event)"
          (onPanelDelete)="panelDelete($event)"
          (newPanelMeta)="newPanelMeta($event)"
          (widgetDeletion)="widgetDeletion($event)"
          (userInputReceived)="userInputHandler($event)"
          (widgetSelection)="widgetSelection($event, panel)"
          [selectedWidgetId]="pageService.selectedWidget"
          [selectedPanelId]="activePanel"
          [hoveredPanelId]="hoveredPanelId"
          [panelMeta]="panel"
          [builderMode]="builderMode"
          [pageMeta]="currentPageMeta"
        >
        </app-panel>
        <!-- (showNewPanelPlaceholder)="showNewPanelPlaceholder($event)" -->
      </div>

      <div class="new-panel-placeholder" *ngIf="pageService.dragNewPanelPlaceholder.newIndex === currentPageMeta.panels.length"></div>
    
    </div>
  </div>
  <br/><br/>
</ng-template>
<ng-template #elseTemplate>
  <div *ngIf="ready" class="page-wrap">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <div fxFlex="100%" *ngFor="let panel of currentPageMeta.panels" class="panel">
        <app-panel [panelMeta]="panel" [pageMeta]="currentPageMeta" [builderMode]="builderMode" (userInputReceived)="userInputHandler($event)">
        </app-panel>
      </div>
    </div>
  </div>
</ng-template>

<!-- <pre>{{ currentPageMeta | json }}</pre> -->
