import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { environment } from 'src/environments/environment';
// import jwt from 'jsonwebtoken';

import { TokenUtil } from '../../core/services/TokenUtil.service'
import { HttpCacheService } from 'src/app/core/services/HttpCacheService';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  creator_email: any;
  creator_workspace_id: any;
  bloomUserMap: any = {};
  formPanelLoaded: boolean = false;
  bloomUserLoggedIn: boolean = false;
  bloomLoginPageCode:string = "";
  constructor(
    private TokenUtil: TokenUtil,
    private authService: AuthServiceService,
    private http: HttpClient,
    private connectionService: ConnectionService,
    private httpCs: HttpCacheService
  ) { }

  isTimerSet: any = {};   //will be of type (page_code: boolean)[]
  userMadeChanges = new BehaviorSubject<boolean>(false);
  $userMadeChanges = this.userMadeChanges.asObservable()

  fetchedBloomMeta = new BehaviorSubject<any>('');
  get_bloomMeta = this.fetchedBloomMeta.asObservable()

  pageMeta = new BehaviorSubject<any>('');
  get_pageMeta = this.pageMeta.asObservable()

  page_structure = new BehaviorSubject<any>('');
  get_page_structure = this.page_structure.asObservable();
  loadedPageStructure: any = [];

  contextChanged: any = new Subject<any>()
  $contextChanged: any = this.contextChanged.asObservable();
  public setContextChanged(event: any) {
    this.contextChanged.next(event)
  }

  pageModel: any;

  // bloomList = new BehaviorSubject<any>('');
  // getBloomList = this.bloomList.asObservable()

  publisher_email: string = ''

  /**
   * this variable is set from preview dialog onInit
   * and reset from preview dialog onDestroy
   *
   * this helps in setting the version as draft instead of latest when version is not supplied in url
   */
  previewMode: boolean = false;
  publishedMode: boolean = false;

  bloomMeta:any

  BOX_TOKEN = environment.DB_BOX_TOKEN
  BASE_URL_MONGO = environment.BOX_URL + "/mongodb"
  BASE_URL_BOX = environment.BOX_URL

  resetBloomData(){
    this.bloomMeta = null;
    // this.pageMeta.unsubscribe()
  }

  async getAutorizationTokenHeader() {
    var result = { Authorization: "" };
    var token = await this.TokenUtil.getStatelessToken();
    result.Authorization = `Bearer ${token}`;
    return result;
  }

  /**
   * creates a new page in DB 
   */
  async create(body?: any) {
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }
    var url = `${environment.SERVER_BASE_URL}/bloom/page`
    // var url = `http://localhost:8081/api/bloom/page`

    try {
      let response: any = await this.http.post(url, body, options).toPromise();
      console.log("[META SERVICE] page create response ------>", response);
      return response.data[0]
    } catch (e) {
      console.error("[META-SERVICE: CREATE: BOX-HTTP] Error on create:", e)
      throw e;
    }
  }

  async create_page_structure(body?: any) {
    console.log("printing", body)
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    var url = `${environment.SERVER_BASE_URL}/bloom/pagestructure`
    // var url = `http://localhost:8081/api/bloom/pagestructure`

    try {
      let response: any = await this.http.post(url, body, options).toPromise();
      console.log("create page_structure response ---->", response);
      return response.data[0]
    } catch (e) {
      console.error("[BOX-HTTP] Error on create page_structure:", e)
      throw e;
    }
  }

  async create_bloom(body?: any) {
    console.log("printing", body)
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    var url = `${environment.SERVER_BASE_URL}/bloom`
    // var url = `http://localhost:8081/api/bloom`

    try {
      let response: any = await this.http.post(url, body, options).toPromise();
      console.log("create bloom response ---->", response);
      return response.data[0]
    } catch (e) {
      console.error("[BOX-HTTP] Error on create bloom:", e)
      throw e;
    }
  }

  /**
   * Fetch a page meta
   * @param id page id
   * @returns page meta
   */
  async get(id?: any) {
    console.log("get request will be sent", id)
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    var url = `${environment.SERVER_BASE_URL}/bloom/page/${id}`
    // var url = `http://localhost:8081/api/bloom/page/${id}`

    try {
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get response ---->", response);

      this.pageMeta.next(response.data[0])

      return response.data[0]
    } catch (e) {
      console.error("[BOX-HTTP] Error on get:", e)
      throw e;
    }
  }

  async update(data: any) {
    console.log("update hit", data)
    this.pageMeta.next(data)
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }
    var url = `${environment.SERVER_BASE_URL}/bloom/page`
    // var url = `http://localhost:8081/api/bloom/page`
    console.log("will update page", data)
    try {
      let response: any = await this.http.put(url, data, options).toPromise();
      // console.log("update response ---->", response);

      // console.log("pageMeta current value", this.pageMeta.value)
      // if(this.pageMeta.value._id == data._id){
      //   this.pageMeta.next(data)
      // }

      return response.data[0]
    } catch (e) {
      console.error("[BOX-HTTP] Error on update:", e)
      throw e;
    }
  }


  async updatePageStructure(data: any) {
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }
    var url = `${environment.SERVER_BASE_URL}/bloom/pagestructure`
    // var url = `http://localhost:8081/api/bloom/pagestructure`
    try {
      let response: any = await this.http.put(url, data, options).toPromise();
      // console.log("update response ---->", response);
      this.page_structure.next(response.data[0]);
      this.loadedPageStructure = response.data[0];
      return response.data[0]
    } catch (e) {
      console.error("[BOX-HTTP] Error on update:", e)
      throw e;
    }
  }

  async deletePage(pageId: any) {
    console.log("printing in delete page", pageId)

    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }
    var url = `${environment.SERVER_BASE_URL}/bloom/page/${pageId}`
    // var url = `http://localhost:8081/api/bloom/${bloom._id}`

    try {
      let response: any = await this.http.delete(url, options).toPromise();
      // console.log("delete response ---->", response);
      return response
    } catch (e) {
      console.error("[BOX-HTTP] Error on page delete:", e)
      throw e;
    }
  }


  async updateBloom(newBloomMeta?: any) {
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }
    var url = `${environment.SERVER_BASE_URL}/bloom/`
    // var url = `http://localhost:8081/api/bloom/`

    try {
      let response: any = await this.http.put(url, newBloomMeta, options).toPromise();
      console.log("bloom update response ---->", response);

      // if it is a draft update, push into observable to make it available to all component
      if (newBloomMeta.version == 'draft') {
        this.fetchedBloomMeta.next(response.data[0])
        this.bloomMeta = response.data[0];
      }

      return response.data[0]
    } catch (e) {
      console.error("[BOX-HTTP] Error on bloom update:", e)
      throw e;
    }
  }


  async deleteBloom(bloom?: any) {
    console.log("printing in delete bloom", bloom)

    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }
    var url = `${environment.SERVER_BASE_URL}/bloom/${bloom._id}`
    // var url = `http://localhost:8081/api/bloom/${bloom._id}`

    try {
      let response: any = await this.http.delete(url, options).toPromise();
      // console.log("delete response ---->", response);
      return response
    } catch (e) {
      console.error("[BOX-HTTP] Error on bloom delete:", e)
      throw e;
    }
  }


  async getBloomAndPageStructure(code: any, version?: string) {
    let filter: string = "code=" + code + "|string"
    if (!version || version == 'latest') {
      filter = filter + ',latest=true|boolean'
    } else if (version == 'draft') {
      filter = filter + ',version=draft|string'
    } else {
      filter = filter + ',version=' + version + '|string'
    }

    var options = { headers: { 
        Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}`
      } 
    }
    var url = `${environment.SERVER_BASE_URL}/bloom/structure?filter=${filter}`
    try {
      let response: any = await this.http.get(url, options).toPromise();
      // let response: any = await this.httpCs.get(url, options);
      console.log("get bloom response ---->", response);
      let bloomMeta
      if (response?.data) {
        bloomMeta = response.data
        //save the publisher email
        this.publisher_email = bloomMeta.publisher_email || '';
        this.creator_email = bloomMeta.created_by;
        this.creator_workspace_id = bloomMeta.workspace_id;

        this.bloomMeta = bloomMeta;
        this.fetchedBloomMeta.next(bloomMeta);

        this.page_structure.next(this.bloomMeta.page_structure_map);
        this.loadedPageStructure = this.bloomMeta.page_structure_map;

      }
      return bloomMeta
    } catch (e) {
      console.error("[BOX-HTTP] Error on get bloom:", e)
      throw e;
    }
  }

  async getBloom(code: any, version?: string) {

    let filter: string = "code=" + code + "|string"
    if (!version || version == 'latest') {
      filter = filter + ',latest=true|boolean'
    } else if (version == 'draft') {
      filter = filter + ',version=draft|string'
    } else {
      filter = filter + ',version=' + version + '|string'
    }

    console.log("this.connectionService.preAuthenticatedToken", this.connectionService.preAuthenticatedToken)

    var options = { headers: { 
        Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}`
      } 
    }
    var url = `${environment.SERVER_BASE_URL}/bloom?filter=${filter}`
    // var url = `http://localhost:8081/api/bloom?filter=${filter}`


    console.log("get bloom request will be sent", url, ', filter:', filter)

    try {
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get bloom response ---->", response);
      let bloomMeta
      if (response.data.length && response.data[0]) {
        bloomMeta = response.data[0]

        //save the publisher email
        this.publisher_email = bloomMeta.publisher_email || '';
        this.creator_email = bloomMeta.created_by;
        this.creator_workspace_id = bloomMeta.workspace_id;

        this.fetchedBloomMeta.next(bloomMeta)
        this.bloomMeta = bloomMeta;

        return bloomMeta
      }
      return null
    } catch (e) {
      console.error("[BOX-HTTP] Error on get bloom:", e)
      throw e;
    }
  }

  async getBloomByCode(code: any, ownerEmail?: any) {
    let token = ownerEmail ? this.connectionService.getPreAuthenticatedTokenForEmail(ownerEmail) : this.connectionService.preAuthenticatedToken;
    var options = { headers: { Authorization: `PreAuthenticatedToken ${token}` } }

    let filter = `code=${code}|string`;

    var url = `${environment.SERVER_BASE_URL}/bloom?filter=${filter}`
    try {
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get bloom by ID, response ---->", response);
      return response?.data || null;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get bloom by id:", e)
      throw e;
    }
  }


  async getBloomByWorkspace(
    wid: any,
    version: string = '',
    template: boolean = false,
    pageNumber: number = 1,
    pageSize: number = 100
  ) {
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    let filter = "workspace_id=" + wid + "|string"
    if (version) {
      filter = filter + `,version=${version}|string`
    }
    if (template) {
      filter = filter + `,template=true|boolean`
    }
    let page = `${pageNumber}|${pageSize}|100`
    let sort = "modified_at=DESC"

    var url = `${environment.SERVER_BASE_URL}/bloom?filter=${filter}&sort=${sort}&page=${page}`
    // var url = `http://localhost:8081/api/bloom?filter=${filter}&sort=${sort}&page=${page}`

    try {
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get bloomList by workspace ID, response ---->", response);
      return response.data
    } catch (e) {
      console.error("[BOX-HTTP] Error on get bloom by workspace:", e)
      throw e;
    }
  }


  /**
   * config is used for searching, its an array of objects of type
   * {
   *    key: string,
   *    value: string,
   *    operator?: string, // = | != | < | > etc
   *    dataType: string
   * }
   */
  async getTemplateBlooms(pageNumber: number = 1, pageSize: number = 10, config: any[] = []) {
    console.log("get template blooms hit with filters", config)
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    let page = `${pageNumber}|${pageSize}|100`
    let filter = "template=true|boolean"

    if (config.length) {
      config.forEach(item => {
        filter += `,${item.key}${item.operator || '='}${item.value}|${item.dataType || ''}`
      })
    }
    console.log("filter string", filter)

    var url = `${environment.SERVER_BASE_URL}/bloom?filter=${filter}&page=${page}`
    console.log("url", url)
    // var url = `http://localhost:8081/api/bloom?filter=${filter}&page=${page}`

    try {
      let response: any = await this.http.get(url, options).toPromise();
      console.log("template blooms, response ---->", response);
      return response
    } catch (e) {
      console.error("[BOX-HTTP] Error on getting template blooms:", e)
      throw e;
    }
  }


  async getPageStructure(id?: any) {
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    var url = `${environment.SERVER_BASE_URL}/bloom/pagestructure/${id}`
    // var url = `http://localhost:8081/api/bloom/pagestructure/${id}`

    try {
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get pagestr response ------------->", response);
      // console.log("get pagestr data ---->", response.data.result.data[0]);

      this.page_structure.next(response.data[0])
      this.loadedPageStructure = response.data[0];

      return response.data[0]
    } catch (e) {
      console.error("[BOX-HTTP] Error on get pagestr:", e)
      throw e;
    }
  }

  async getUser() {
    let publisherData: any;
    // console.log("authService.userProfile", this.authService.userProfile)
    // console.log("authService.profile", this.authService.profile)
    // this.authService.profileLoaded.subscribe(profile => {
    //   console.log("profile from subscription", profile)
    // })
    if(!this.publisher_email) return this.authService.userProfile;
    let getUserUrl = `${environment.SERVER_BASE_URL}/user/${this.publisher_email}`

    // console.log("getUserUrl:", getUserUrl)
    try {
      let response = await this.http.get(getUserUrl).toPromise()
      publisherData = response;
      if(this.publishedMode) {
        this.authService.profile = publisherData;
        this.connectionService.userProfile = publisherData;
        this.connectionService.getPreAuthenticatedToken();
      }
    } catch (err) {
      console.log("could not load publisher details", err)
      return
    }
    // console.log("publisher data received", publisherData)
    return publisherData
  }

  async getClonedPageIds(oldIds: string[]) {
    console.log("Pages with following Ids will be cloned and inserted with new Ids", oldIds)
    let collection = "page"
    var options = { headers: { boxconfigToken: this.BOX_TOKEN } }

    var tokenMap = await this.getAutorizationTokenHeader();
    options.headers = Object.assign(options.headers, tokenMap)

    var boxUrl = `${this.BASE_URL_MONGO}/${collection}/get`
    let payload = {
      parameters: {
        data: [
          oldIds
        ]
      }
    }
    // try {
    //   let response: any = await this.http.post(boxUrl, payload, options).toPromise();
    //   console.log("get bloom response ---->", response);
    //   let bloomMeta = response.result.data[0]

    //   //save the publisher email
    //   this.publisher_email = bloomMeta.publisher_email

    //   this.fetchedBloomMeta.next(bloomMeta)

    //   return bloomMeta
    // } catch(e){
    //     console.error("[BOX-HTTP] Error on get bloom:", e)
    //     throw e;
    // }
    let newIds = []
    oldIds.forEach(_ => {
      newIds.push(Math.floor(Math.random() * 11000000) + 10000000)
    });
    console.log("duplicate page Ids will be returned", newIds)
    return newIds
  }

  async getAllPages(idArray: string[]) {
    let promiseArray: any[] = [];
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    console.log(idArray);
    idArray.forEach((id: string) => {
      var url = `${environment.SERVER_BASE_URL}/bloom/page/${id}`
      // var url = `http://localhost:8081/api/bloom/page/${id}`
      promiseArray.push(this.http.get(url, options).toPromise());
    });
    console.log(promiseArray)
    try {
      let requestArray = Promise.all(promiseArray);
      var result: any[] = [];
      let responseArray = await requestArray.then((res => {
        console.log("get all pages response ---> ", res)
        res.forEach((item: any) => {
          result.push(item.data[0])
        })
        return result
      }));
      console.log(responseArray);
      return responseArray;
    } catch (err) {
      console.error(err)
      return []
    }
  }

  async getBloomConnections(pagestructureid: string) {
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    var url = `${environment.SERVER_BASE_URL}/bloom/util/connlist/${pagestructureid}`
    // var url = `http://localhost:8081/api/bloom/util/connlist/${pagestructureid}`
    try {
      let response: any = await this.http.get(url, options).toPromise();
      console.log("get bloom connection list response ------------->", response);

      return response.data
    } catch (e) {
      console.error("[BOX-HTTP] Error on getting connection list:", e)
      throw e;
    }
  }

  async replaceBloomConnections(replacementMap: any[], bloom: any, workspaceId: string, createdBy: string, bloomName: string) {
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }

    var url = `${environment.SERVER_BASE_URL}/bloom/util/replaceconn`
    // var url = `http://localhost:8081/api/bloom/util/replaceconn`

    // modify bloom record


    let payload: any = {
      replacementMap: replacementMap,
      bloom: bloom,
      workspace_id: workspaceId,
      created_by: createdBy,
      bloom_name: bloomName
    }
    console.log("payload prepared for replace conn", payload)

    try {
      let response: any = await this.http.put(url, payload, options).toPromise();
      console.log("replace bloom connections response ------------->", response);

      return response.data
    } catch (e) {
      console.error("[BOX-HTTP] Error on replace connections:", e)
      throw e;
    }
  }


  async createDraft(bloom){
    console.log("create draft bloom for ", bloom)
    var options = { headers: { Authorization: `PreAuthenticatedToken ${this.connectionService.preAuthenticatedToken}` } }
    var url = `${environment.SERVER_BASE_URL}/bloom/createdraft`
    let payload: any = bloom
    console.log("payload prepared for creating draft bloom", payload)

    try {
      let response: any = await this.http.put(url, payload, options).toPromise();
      console.log("draft bloom create response ------------->", response);

      return response.data
    } catch (e) {
      console.error("[BOX-HTTP] Error in creating draft bloom:", e)
      throw e;
    }

  }


  userChangeDetected(){
    this.userMadeChanges.next(true)
  }

}
