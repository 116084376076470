import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FlexAlignStyleBuilder } from '@ngbracket/ngx-layout';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';


@Component({
  selector: 'app-numberinput',
  templateUrl: './numberinput.component.html',
  styleUrls: ['./numberinput.component.css']
})
export class NumberinputComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any = {};

  // value = "";
  hoveredNow: boolean = false;
  private destroy:any = new Subject();

  @ViewChild('menuTrigger') numberInputMenuTrigger: MatMenuTrigger;

  constructor(
    public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)
    console.log("in text input constructor", this.widgetMeta);
  }

  ngOnInit(): void {

    super.ngOnInit()
    console.log("in text input onInit", this.widgetMeta)
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
    super.generateStyles();
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  // ngDoCheck(): void {
  //   // console.log("widgetMeta in doCheck", this.widgetMeta)
  //   this.value = this.widgetMeta.config?.textContent?.value
  // }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes in number input widget", changes.widgetMeta)
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.numberInputMenuTrigger && this.numberInputMenuTrigger.menuOpen) {
        this.numberInputMenuTrigger?.closeMenu();
      }
    }
    if (changes.widgetMeta && changes.widgetMeta.currentValue) {
      this.widgetMeta = changes.widgetMeta.currentValue
      this.widgetMeta.config.textContent.value = this.widgetMeta.config?.textContent?.value ? this.widgetMeta.config?.textContent?.value : "0";
      console.log("value updated for number input:", this.widgetMeta.config?.textContent?.value)
      this.emitUserInput()
      this.setContextActions()
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }
  }

  //----------------------------------- FUNCTIONS ---------------------------------------

  setContextActions() {
    this.contextMenuActions = {
      actions: [
        "edit",
        "appearance",
      ],
    };
    if(this.widgetMeta.textFormat){
      this.contextMenuActions.actions.push(...[
        "bold",
        "underline",
        "italic",
        "color",
        "fontSize",
        "fontFamily",
      ])
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions);
  }

  action(event: any) {
    console.log("action received", event);
    switch (event.actionType) {
      case "updateStyles":
        if (event?.data) {
          this.widgetMeta = JSON.parse(JSON.stringify(event.data));
          console.log("localMeta changed", this.widgetMeta)
          this.newWidgetMeta.emit(this.widgetMeta)
          // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
          super.generateStyles();
          // console.log("styles re-generated", this.styles)
        }
        break;
      // case "bold":
      //   break;
      // case "italic":
      //   break;
      // case "underline":
      //   break;
      // case "fontSize":
      //   break;
      // case "fontFamily":

      //   break;
      // case "color":
      //     break;
      case "delete":
        this.onDelete();
        break;
    }
  }

  onClick() {
    if (!this.builderMode) {
      return;
    }

    console.log("textInput widget clicked in widget")
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.numberInputMenuTrigger?.closeMenu();
  }

  emitUserInput(event?: any) {
    console.log("input change detected", this.widgetMeta.config.textContent.value)
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      // value: String(this.widgetMeta.config.textContent.value)
      value: String(this.widgetMeta.config.textContent.value)+'|number'
    }

    //this only applies when using textInput Component for widget configuration
    if (this.widgetMeta.hasOwnProperty('propName')) {
      userInput.propName = this.widgetMeta.propName
    }

    if (this.widgetMeta.config.textContent.value !== '') {
      this.userInputReceived.emit(userInput)
    }

    if(this.builderMode){
      this.newWidgetMeta.emit(this.widgetMeta)
    }
  }

}
